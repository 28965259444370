import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { TextBox, SecondaryButton, SecondaryCheckbox, DialogContents, CustomButton, Multiselect } from '../../../Core/FormInput';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { IconButton } from '@material-ui/core';
import DisplayGrid from './DisplayGrid';
import Tooltip from '@material-ui/core/Tooltip';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import MenuItem from '@material-ui/core/MenuItem';
import { TechWriteProvider, TechWriteScreenContext } from './TechWriteProvider';
import Button from '@material-ui/core/Button';
import './TechnicianStyle.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputLabel } from '@material-ui/core';
import ConfirmationModal from '../../../Core/Controls/Dialog/ConfirmationModal';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { AppStorage } from '../../../Core/Service/storage-service';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AppContext from '../../../App/AppContext';

const TechWriteUp = (props) => {
    const {
        printDocument,
        TechBtnClick,
        fromStep,
        masterData,
        state,
        selectedData,
        clear,
        DisplayGridState,
        GetTechnicianSign,
        handleSubmit,
        handleCheck,
        fieldChange,
        AutoWorkAccomplish,
        GlobalCss,
        AutoFrChange,
        handleAddClick,
        handleRemoveClick,
        handleInputChange,
        getStandartdFromChild,
        pullAndUpdateState,
        inputList,
        portalSettings,
        showConfirmationModal,
        hideConfirmationModal,
        zoomOutFun,
        zoomInFun,
        clearSig,
        isTechnician,
        saveButtonConfirmationValidation,
        hideSaveConfirmModal
    } = useContext(TechWriteScreenContext);
    const { hideModal, showToast } = useContext(AppContext);

    const hideBrace = state.technicianName || state.technicianDateSigned;
    const hideBrace2 = state.teamLeaderName || state.teamLeaderDateSigned;
    const ShowSubmit = state.status === 'TS'; //imgteamleadersign-Workshop controller Signature

    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(value);
        showToast('Copied!');
    };

    const TabBody = (jobID) => {
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Date
                        </FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="date"
                            value={state.date}
                            autoComplete="new-password"
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            disabled={state.isTechReadonly}
                        />
                        <span className="mandatoryfields">{state.errors['date']}</span>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Odometer Reading
                        </FormLabel>
                        <TextBox
                            disabled={state.isTechReadonly}
                            required
                            type="number"
                            name="readingtaken"
                            value={state.readingtaken}
                            onChange={fieldChange}
                            autoFocus={true}
                        />
                        <span className="mandatoryfields">{state.errors['readingtaken']}</span>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormLabel component="legend">Odometer Reading Unit</FormLabel>
                        <TextBox
                            disabled={state.isTechReadonly}
                            select
                            value={state.technicianNotesReadingUnit}
                            name="technicianNotesReadingUnit"
                            onChange={fieldChange}
                            rows={2}
                        >
                            {state.odometerUnitsVM?.length > 0 &&
                                state.odometerUnitsVM.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                        </TextBox>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {inputList.map((x, i) => (
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={4}>
                                    <FormLabel component="legend" className="mandatoryfields">
                                        Complaint{' '}
                                        {x.technicianNotesItemFault && (
                                            <FileCopyIcon
                                                style={{ fontSize: '15px', cursor: 'pointer' }}
                                                onClick={() => copyToClipboard(x.technicianNotesItemFault)}
                                            />
                                        )}
                                        <Tooltip title="Select Standard Write-Up" aria-label="add">
                                            <IconButton
                                                onClick={() => {
                                                    DisplayGridState(i);
                                                }}
                                                style={{
                                                    padding: '1px',
                                                    marginTop: '-9px',
                                                    marginLeft: '10px'
                                                }}
                                                disabled={state.isTechReadonly}
                                            >
                                                <NoteAddIcon color="secondary" fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </FormLabel>
                                    <TextBox
                                        name="technicianNotesItemFault"
                                        // autoFocus={true}
                                        value={x.technicianNotesItemFault}
                                        onChange={(e) => handleInputChange(e, i)}
                                        multiline
                                        disabled={state.isTechReadonly}
                                        rows={portalSettings.portalSettingTechnWriteupHeight || 4}
                                    />
                                    <span className="mandatoryfields">{state.errors['complaint']}</span>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <FormLabel component="legend" className="mandatoryfields">
                                        Cause{' '}
                                        {x.technicianNotesItemCause && (
                                            <FileCopyIcon
                                                style={{ fontSize: '15px', cursor: 'pointer' }}
                                                onClick={() => copyToClipboard(x.technicianNotesItemCause)}
                                            />
                                        )}
                                    </FormLabel>
                                    <TextBox
                                        name="technicianNotesItemCause"
                                        disabled={state.isTechReadonly}
                                        value={x.technicianNotesItemCause}
                                        onChange={(e) => handleInputChange(e, i)}
                                        multiline
                                        rows={portalSettings.portalSettingTechnWriteupHeight || 4}
                                    />
                                    <span className="mandatoryfields">{state.errors['cause']}</span>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormLabel component="legend" className="mandatoryfields">
                                        Cure{' '}
                                        {x.technicianNotesItemDiagnosis && (
                                            <FileCopyIcon
                                                style={{ fontSize: '15px', cursor: 'pointer' }}
                                                onClick={() => copyToClipboard(x.technicianNotesItemDiagnosis)}
                                            />
                                        )}
                                    </FormLabel>
                                    {inputList.length !== 1 && (
                                        <Tooltip title="Remove Standard Write-Up" aria-label="add">
                                            <IconButton disabled={state.isTechReadonly} onClick={() => handleRemoveClick(i)} className="rightButton">
                                                <RemoveCircleIcon color="primary" fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {inputList.length - 1 === i && (
                                        <Tooltip title="Add Standard Write-Up" aria-label="add">
                                            <IconButton
                                                disabled={state.isTechReadonly}
                                                onClick={handleAddClick}
                                                className="rightButton"
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                            >
                                                <AddBoxIcon color="secondary" fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <TextBox
                                        name="technicianNotesItemDiagnosis"
                                        disabled={state.isTechReadonly}
                                        value={x.technicianNotesItemDiagnosis}
                                        onChange={(e) => handleInputChange(e, i)}
                                        multiline
                                        rows={portalSettings.portalSettingTechnWriteupHeight || 4}
                                    />
                                    <span className="mandatoryfields">{state.errors['cure']}</span>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    {!portalSettings.workflowStepOptionsAsButtonGroup && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <FormLabel component="legend">Work Accomplished</FormLabel>
                                <Multiselect
                                    label="Work Accomplished"
                                    options={masterData.workaccomplishcodes}
                                    value={selectedData.selectedWorkAccomplishCodes}
                                    onChange={AutoWorkAccomplish}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormLabel component="legend">Failure Reasons</FormLabel>
                                <Multiselect
                                    label="Failu Rereason"
                                    options={masterData.failureCodes}
                                    value={selectedData.selectedFailureCodes}
                                    onChange={AutoFrChange}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} className="gridcontainer">
                        <div>
                            <Typography style={{ float: 'left' }}>Diagnostic Road Test</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3}>
                                    <FormLabel component="legend">Mileage In</FormLabel>
                                    <TextBox disabled={state.isTechReadonly} value={state.mileagein} type="number" name="mileagein" onChange={fieldChange} />
                                </Grid>
                                <Grid item xs={12} sm={3} className="marginBottom">
                                    <FormLabel component="legend">Mileage Out</FormLabel>
                                    <TextBox disabled={state.isTechReadonly} value={state.mileageout} type="number" name="mileageout" onChange={fieldChange} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormLabel component="legend">Time In</FormLabel>
                                    <TextBox
                                        disabled={state.isTechReadonly}
                                        name="timein"
                                        value={state.timein}
                                        type="time"
                                        onChange={fieldChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{
                                            step: 300 // 5 min
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} className="marginBottom">
                                    <FormLabel component="legend">Time Out</FormLabel>
                                    <TextBox
                                        disabled={state.isTechReadonly}
                                        name="timeout"
                                        type="time"
                                        value={state.timeout}
                                        onChange={fieldChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{
                                            step: 300 // 5 min
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    disabled={state.isTechReadonly}
                                    checked={state.smoketest}
                                    onChange={handleCheck}
                                    value={state.smoketest}
                                    name="smoketest"
                                />
                            }
                            label="Smoke Test Performed"
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    disabled={state.isTechReadonly}
                                    checked={state.serviceindicator}
                                    onChange={handleCheck}
                                    value={state.serviceindicator}
                                    name="serviceindicator"
                                />
                            }
                            label="Service Indicator Reset"
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    disabled={state.isTechReadonly}
                                    checked={state.radiocode}
                                    onChange={handleCheck}
                                    name="radiocode"
                                    value={state.radiocode}
                                />
                            }
                            label="Radio Code Reset"
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    disabled={state.isTechReadonly}
                                    checked={state.timeclock}
                                    onChange={handleCheck}
                                    name="timeclock"
                                    value={state.timeclock}
                                />
                            }
                            label="Time Clock Reset"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomeSignPad
                            onChange={GetTechnicianSign}
                            disabled={state.isTechReadonly}
                            content={
                                <FormLabel
                                    component="legend"
                                    className="mandatoryfields"
                                    style={{
                                        marginBottom: '9px',
                                        marginTop: '5px'
                                    }}
                                >
                                    Technician {hideBrace ? <span> ({`${state.technicianName || ''} ${state.technicianDateSigned}`})</span> : ' Signature'}
                                </FormLabel>
                            }
                            sign={state.imgtechniciansign}
                            name="techniciansign"
                            // hideBtn={!isTechnician}
                            hideBtn={!ShowSubmit ? !(isTechnician && !state.isTechReadonly) : true}
                            clearSig={clearSig}
                        />
                        <span className="mandatoryfields">{state.errors['techniciansign']}</span>
                    </Grid>

                    {state.imgteamleadersign && (
                        <Grid item xs={12} sm={6}>
                            <CustomeSignPad
                                disabled={state.isTechReadonly}
                                content={
                                    <FormLabel
                                        component="legend"
                                        className="mandatoryfields"
                                        style={{
                                            marginBottom: '9px',
                                            marginTop: '5px'
                                        }}
                                    >
                                        Workshop Controller
                                        {hideBrace2 ? <span> ({`${state.teamLeaderName || ''} ${state.teamLeaderDateSigned}`})</span> : ' Signature'}
                                    </FormLabel>
                                }
                                sign={state.imgteamleadersign}
                                name="teamleadersign"
                                hideBtn={true}
                            />
                        </Grid>
                    )}
                    {/* {!state.isTechReadonly && ( */}
                    <Grid item xs={12} sm={6} style={{ marginTop: state.imgtechniciansign ? 10 : 5 }}>
                        {!ShowSubmit
                            ? !state.imgteamleadersign &&
                              isTechnician &&
                              !state.isTechReadonly && (
                                  <Grid container spacing={2} item>
                                      {portalSettings.portalSettingsShowTechWriteUpSaveBtn && (
                                          <Grid item xs={6}>
                                              <InputLabel>{'\u{2800}'}</InputLabel>
                                              <SecondaryButton className="btn_add setFullWidth" onClick={saveButtonConfirmationValidation}>
                                                  Save
                                              </SecondaryButton>
                                          </Grid>
                                      )}
                                      {portalSettings.portalSettingDisplaySubmitbutton && (
                                          <Grid item xs={6}>
                                              <InputLabel>{'\u{2800}'}</InputLabel>
                                              <SecondaryButton className="btn_add setFullWidth" onClick={showConfirmationModal}>
                                                  Submit
                                              </SecondaryButton>
                                          </Grid>
                                      )}
                                  </Grid>
                              )
                            : null}
                    </Grid>
                    {/* )} */}
                </Grid>
                {state.showConfirmModal ? (
                    <DialogComp title="Confirmation" onClose={hideConfirmationModal} maxWidth="sm">
                        <ConfirmationModal
                            message={`By submitting you will not be able to make any further changes to the form and it transferred to the next queue. If you need to make further changes please click cancel and select the save option.`}
                            handleSubmit={() => handleSubmit('S')}
                            handleCancel={hideConfirmationModal}
                        />
                    </DialogComp>
                ) : null}
                {state.showSaveConfirmModal ? (
                    <DialogComp title="Confirmation" onClose={hideSaveConfirmModal} maxWidth="sm">
                        <ConfirmationModal
                            message={`Signature is only allowed once you have completed write up and click the Submit button`}
                            handleCancel={hideSaveConfirmModal}
                        />
                    </DialogComp>
                ) : null}
            </>
        );
    };

    return (
        <>
            {state.showGrid ? <DisplayGrid onFormSubmit={pullAndUpdateState} onStandardWriteup={getStandartdFromChild} /> : null}
            <GlobalCss />
            {!fromStep && (
                <Breadcrumbs separator=">>" aria-label="breadcrumb">
                    <Link color="inherit" to={'/'}>
                        Technician App
                    </Link>
                    <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                        Technician Write-up
                    </Typography>
                </Breadcrumbs>
            )}
            <div>
                <DialogContents className="mt-11" style={{ background: 'white' }}>
                    <Grid container spacing={1} style={{ position: 'relative' }}>
                        Registration Number:&nbsp; <b> {state.registration}</b>
                        <Grid item sm={3}>
                            WIP No:&nbsp; <b> {state.wipNumber}</b>
                        </Grid>
                        <Grid item sm={3}>
                            Customer:&nbsp; <b> {state.customerName}</b>
                        </Grid>
                        <Grid item sm={3} style={{ position: 'absolute', top: '-4px', right: '20px' }} id="zommContainer">
                            <b>Zoom</b>
                            <CustomButton
                                color="secondary"
                                disabled={state.fontResize == 16}
                                icon={ZoomOutIcon}
                                onClick={zoomOutFun}
                                toolTipTitle="Zoom Out"
                                iconFontSize={28}
                            />
                            <CustomButton
                                color="secondary"
                                disabled={state.fontResize == 28}
                                icon={ZoomInIcon}
                                onClick={zoomInFun}
                                toolTipTitle="Zoom In"
                                iconFontSize={28}
                            />
                            <CustomButton
                                color="secondary"
                                icon={CloudDownloadIcon}
                                onClick={printDocument}
                                toolTipTitle="Download this document"
                                iconFontSize={28}
                            />
                            {/* <CircularProgress size={20} /> */}
                        </Grid>
                        <Grid item sm={12}></Grid>
                        {state.showCircularLoader ? (
                            <Grid item sm={12}>
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <>
                                {state.technicians.map((m) => (
                                    <div className="tech-btn-group">
                                        <Button
                                            className={state.technicianNotesJobID == m.technicianJobID ? 'active-btn' : 'all-btn'}
                                            onClick={() => TechBtnClick(m.technicianJobID)}
                                        >
                                            {m.technician}
                                        </Button>
                                    </div>
                                ))}

                                {TabBody()}
                            </>
                        )}
                    </Grid>
                </DialogContents>
            </div>
        </>
    );
};

const Screen = (props) => {
    return (
        <TechWriteProvider {...props}>
            <TechWriteUp {...props} />
        </TechWriteProvider>
    );
};
export default Screen;
