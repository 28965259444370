import React, { useContext, useEffect, useState } from 'react';
import './authStyle.scss';
import ReactDOM from 'react-dom';
import { QRCodeSVG } from 'qrcode.react';
import { SecondaryButton, TextBox } from '../FormInput';
import { CircularProgress, Grid, Hidden, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import MenuIcon from '@material-ui/icons/Menu';
import { getAuthenticatorCode, postAuthenticationCode, postAuthenticationValidateCode } from '../Service/authenticator-service';
import AppContext from '../../App/AppContext';
import { AppStorage } from '../Service/storage-service';
import CancelIcon from '@material-ui/icons/Cancel';
import QrImage from './QrImage.png';

const AuthenticationScreen = () => {
    // const { portalSettings, showTopNavAndMenu, loggedIn, isTopNav, getUserPreference } = useContext(AppContext);

    const [state, setState] = useState({
        codeUrl: '',
        securityKey: '',
        code: '',
        securityKeyARRAY: []
    });

    const { hideModal, showToast, portalSettings } = useContext(AppContext);
    const img = !!portalSettings.portalSettingMainLogo ? portalSettings.portalSettingMainLogo : `${process.env.PUBLIC_URL}/${portalSettings.portalSettingLogo}`;
    // const [isTwoFA, setIsTwoFA] = useState(AppStorage.getTwoFactorEnabled());
    useEffect(async () => {
        let res = await getAuthenticatorCode();
        if (res.success) {
            setState((st) => ({
                ...st,
                codeUrl: res.data.barcodeUri,
                securityKey: res.data.sharedKey
            }));
        }
    }, []);

    useEffect(() => {
        setState((st) => ({
            ...st,
            securityKeyARRAY: st.securityKey.split(' ')
        }));
    }, [state.securityKey]);

    console.log(state.securityKeyARRAY, 'securityKeyARRAY');

    const handleInput = (e) => {
        const { value } = e.target;
        setState((st) => ({ ...st, code: value }));
    };

    const handleAuth = async () => {
        let res = await postAuthenticationCode(state.code);
        if (res.success) {
            AppStorage.setTwoFlag(true);
            showToast(
                'Your login has been successfully registered for two-factor authentication. You will need to enter the six digit number displayed in the authenticator app when asked to login into the system.'
            );
        }
    };

    // console.log(', 'QrImage');
    return (
        <>
            <Hidden only={['sm', 'lg', 'md', 'xs']}>
                <div className="authMain">
                    <div className="authModal">
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Grid Grid container justifyContent="center">
                                    <Grid item xs={10}>
                                        <div className="authDetails">
                                            <div>
                                                <Typography
                                                    variant="h3"
                                                    style={{ textAlign: 'left', fontWeight: 600, color: '#1f3758', display: 'inline-block' }}
                                                >
                                                    <CancelIcon style={{ fontSize: 50, color: '#ED2312', verticalAlign: 'middle' }} />{' '}
                                                    <span>Two-factor Authentication is Off</span>
                                                </Typography>
                                                <br />

                                                <br />
                                                <Typography variant="h6" style={{ textAlign: 'left' }}>
                                                    Complete authentication by scanning the QR code shown in an Authenticator App
                                                </Typography>
                                                <br />

                                                <Typography variant="h6" style={{ textAlign: 'left' }}>
                                                    You may use Microsoft/Google Authenticator (download from App/Play Store) or any other Authenticator App of
                                                    your choice
                                                </Typography>
                                                <br />
                                                <Typography variant="h6" style={{ textAlign: 'left' }}>
                                                    Once you have downloaded the Authenticator app, you can scan the QR code to continue with the enrollment,
                                                    enter the authentication code displayed in the app and press the Submit button
                                                </Typography>
                                                <br />
                                                <Typography variant="body1" style={{ textAlign: 'left', fontWeight: 600 }}>
                                                    Security Key : &nbsp;
                                                    {state.securityKey}
                                                    {/* </Typography>
                                        <Typography variant="h6" style={{ textAlign: 'left' }}> */}
                                                    {/* {state.securityKeyARRAY.map((map) => {
                                                        return (
                                                            <span
                                                                style={{ marginRight: 3, background: 'rgba(0,0,0,0.6)', color: 'white', padding: '2px 5px ' }}
                                                            >
                                                                {map}
                                                            </span>
                                                        );
                                                    })}{' '} */}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid item xs={12} sm={12} md={5}>
                                <Grid Grid container justifyContent="center">
                                    <Grid item xs={5}>
                                        <div className="notch">-</div>
                                        <div className="MobileView">
                                            <div>
                                                <div className="qrScanner">
                                                    <Typography
                                                        variant="body1"
                                                        style={{ textAlign: 'center', fontSize: 15, fontWeight: 600, marginBottom: 10 }}
                                                    >
                                                        Scan the QR Code below
                                                    </Typography>{' '}
                                                    {/* <img src={img} /> */}
                                                    {state.codeUrl != '' ? (
                                                        <QRCodeSVG
                                                            value={state.codeUrl}
                                                            size={230}
                                                            level="Q"
                                                            fgColor="#1f3758"
                                                            // imageSettings={{
                                                            //     src: `${img}`,
                                                            //     x: undefined,
                                                            //     y: undefined,
                                                            //     height: 32,
                                                            //     width: 100,
                                                            //     excavate: true
                                                            // }}
                                                        />
                                                    ) : (
                                                        <CircularProgress />
                                                    )}
                                                    <br />
                                                    <TextBox placeholder="Authentication Code" className="Textbox" onChange={handleInput} value={state.code} />
                                                    <SecondaryButton fullWidth onClick={handleAuth}>
                                                        Submit
                                                    </SecondaryButton>
                                                    <br />
                                                    {/* <Typography variant="caption" color="primary" style={{ textAlign: 'left' }}>
                                                go back
                                            </Typography> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="buttons">
                                    <MenuIcon color="white" size="small" style={{ fontSize: 17 }} />
                                    &nbsp;&nbsp; &nbsp;&nbsp;
                                    <CropSquareIcon color="white" size="small" style={{ fontSize: 17 }} />
                                    &nbsp;&nbsp; &nbsp;&nbsp;
                                    <ArrowBackIosIcon color="white" size="small" style={{ fontSize: 17 }} />
                                </div> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Hidden>
            <Hidden only={['xl']}>
                <Grid container spacing={0} alignContent="center">
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid Grid container justifyContent="center">
                            <Grid item xs={12}>
                                <div className="authDetails">
                                    <div>
                                        <div style={{ textAlign: 'left', fontWeight: 600, color: '#1f3758', display: 'inline-block' }}>
                                            <CancelIcon className="icon" style={{ color: '#ED2312', verticalAlign: 'middle' }} />{' '}
                                            <span className="mainHeading">Two-factor Authentication is Off</span>
                                        </div>

                                        <div className="subText" style={{ textAlign: 'left' }}>
                                            Complete athentication by scanning the QR code shown in an Authenticator App
                                        </div>

                                        <div className="subText" style={{ textAlign: 'left' }}>
                                            You may use Microsoft/Google Authenticator (download from App/Play Store) or any other Authenticator App of your
                                            choice
                                        </div>
                                        <div className="subText" style={{ textAlign: 'left' }}>
                                            Once you have downloaded the Authenticator app, you can scan the QR code to continue with the enrollment, enter the
                                            authentication code displayed in the app and press the Submit button
                                        </div>
                                        <div className="securityflow" style={{ textAlign: 'left', fontWeight: 600 }}>
                                            Security Key : &nbsp;
                                            {state.securityKey}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid item xs={12} sm={12} md={5}>
                        <Grid Grid container justifyContent="center">
                            <Grid item xs={12}>
                                <div className="MobileView">
                                    <div>
                                        <div className="qrScanner">
                                            <div style={{ textAlign: 'center', fontSize: 15, fontWeight: 600, marginBottom: 10 }}>Scan the QR Code below</div>{' '}
                                            {state.codeUrl != '' ? (
                                                <QRCodeSVG
                                                    value={state.codeUrl}
                                                    size={150}
                                                    level="Q"
                                                    fgColor="#1f3758"
                                                    // imageSettings={{
                                                    //     src: { QrImage },
                                                    //     x: undefined,
                                                    //     y: undefined,
                                                    //     height: 24,
                                                    //     width: 24,
                                                    //     excavate: true
                                                    // }}
                                                />
                                            ) : (
                                                <CircularProgress />
                                            )}
                                            <br />
                                            <TextBox placeholder="Authentication Code" className="Textbox" onChange={handleInput} value={state.code} />
                                            <SecondaryButton fullWidth onClick={handleAuth}>
                                                Submit
                                            </SecondaryButton>
                                            <br />
                                            {/* <Typography variant="caption" color="primary" style={{ textAlign: 'left' }}>
                                                go back
                                            </Typography> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="buttons">
                                    <MenuIcon color="white" size="small" style={{ fontSize: 17 }} />
                                    &nbsp;&nbsp; &nbsp;&nbsp;
                                    <CropSquareIcon color="white" size="small" style={{ fontSize: 17 }} />
                                    &nbsp;&nbsp; &nbsp;&nbsp;
                                    <ArrowBackIosIcon color="white" size="small" style={{ fontSize: 17 }} />
                                </div> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
        </>
    );
};

export default AuthenticationScreen;
