import { get, post } from './http-calls';

export const getCommunicatorVehicleStatus = async () => {
    let res = await get('/api/communicatorvehcile', { useAuthToken: true });
    return res;
};

export const setCommunicatorVehicleStatus = async (VehicleStatusCode, VehicleStatusSMSText, VehicleStatusSendSMSUpdate) => {
    let res = await post(
        `/api/communicatorvehcile`,
        {
            VehicleStatusCode: VehicleStatusCode,
            VehicleStatusSMSText: VehicleStatusSMSText,
            VehicleStatusSendSMSUpdate: VehicleStatusSendSMSUpdate
        },
        { useAuthToken: true }
    );
    return res;
};

export const getCommunicatorBranches = async () => {
    let res = await get('/api/communicatorbranch', { useAuthToken: true });
    return res;
};

export const getCommunicatorBranch = async (id) => {
    let res = await get(`/api/communicatorbranch/${id}`, { useAuthToken: true });
    return res;
};

export const setCommunicatorBranch = async (
    branchID,
    branchOverdueEmail,
    branchTechnicalAssistanceEmail,
    branchPartStatusEmail,
    branchWorkshopDiaryEmail,
    branchFeedbackEmailAddress,
    branchUrgentActionEmailAddress,
    branchJobApprovedEmail,
    branchCollectionStatusEmail,
    branchCashCustomerMessage
) => {
    let res = await post(
        `/api/communicatorbranch`,
        {
            branchID: branchID,
            branchOverdueEmail: branchOverdueEmail,
            branchTechnicalAssistanceEmail: branchTechnicalAssistanceEmail,
            branchPartStatusEmail: branchPartStatusEmail,
            branchWorkshopDiaryEmail: branchWorkshopDiaryEmail,
            branchFeedbackEmailAddress: branchFeedbackEmailAddress,
            branchUrgentActionEmailAddress: branchUrgentActionEmailAddress,
            branchJobApprovedEmail: branchJobApprovedEmail,
            branchCollectionStatusEmail: branchCollectionStatusEmail,
            branchCashCustomerMessage: branchCashCustomerMessage
        },
        { useAuthToken: true }
    );
    return res;
};

export const getCalendarBookings = async (branchId, month, year) => {
    let res = await get(`/api/CommunicatorBranch/GetBookingsByBranchMonthYear?BranchID=${branchId}&Month=${month}&Year=${year}`, { useAuthToken: true });
    return res;
};

// <---------Admin---------->

export const getBranchParametersScreenDropdown = async () => {
    let res = await get(`BranchParameters/ScreenDropdown`, { useAuthToken: true });
    return res;
};

export const GetApprovedDetails = async (HeaderId) => {
    let res = await get(`VehicleStatus/GetApprovedDetails?HeaderId=${HeaderId}`, { useAuthToken: true });
    return res;
};

export const getBranchParametersById = async (BranchID) => {
    let res = await get(`BranchParameters/${BranchID}`, { useAuthToken: true });
    return res;
};

export const postBranchParameters = async (data) => {
    let res = await post(`BranchParameters`, data, { useAuthToken: true });
    return res;
};

export const getAdminDropDown = async () => {
    let res = await get(`CustomerGroups/ScreenDropDown`, { useAuthToken: true });
    return res;
};

export const getNotificationGroupsById = async (id) => {
    let res = await get(`CustomerGroups/${id}`, { useAuthToken: true });
    return res;
};

export const postNotificationGroups = async (data) => {
    let res = await post(`CustomerGroups`, data, { useAuthToken: true });
    return res;
};

export const getCommunicatorSmsStatus = async (id) => {
    let res = await get(`VehicleStatus/GetVehicleSMSStatus`, { useAuthToken: true });
    return res;
};

export const postCommunicatorSms = async (data) => {
    let res = await post(`VehicleStatus/UpdateVehicleSMSStatus`, data, { useAuthToken: true });
    return res;
};
export const postCommunicatorAddActionLog = async (data) => {
    let res = await post(`Communicators/LogAddUpdate`, data, { useAuthToken: true });
    return res;
};

export const getActionLogByID = async (id) => {
    let res = await get(`Communicators/Log/${id}`, { useAuthToken: true });
    return res;
};

export const getStatusDropdown = async (CurrentStatus) => {
    let res = await get(`VehicleStatus/ScreenDropDown?CurrentStatus=${CurrentStatus}`, { useAuthToken: true });
    return res;
};

export const postVehicleStatus = async (data) => {
    let res = await post(`VehicleStatus/update`, data, { useAuthToken: true });
    return res;
};

export const getVehicleStatusID = async (id) => {
    let res = await get(`VehicleStatus?HeaderRecordID=${id}`, { useAuthToken: true });
    return res;
};

export const getActionLogHistory = async (id) => {
    let res = await get(`Communicators/GetLogHistory/${id}`, { useAuthToken: true });
    return res;
};

export const postActionLogEmailLog = async (data) => {
    let res = await post(`Communicators/EmailActionLog`, data, { useAuthToken: true });
    return res;
};

export const postMakeVisibleToCustome = async (data) => {
    let res = await post(`Communicators/MakeVisibleToCustomer`, data, { useAuthToken: true });
    return res;
};
export const postJobProgressSetVehicleArrivedStatus = async (data) => {
    let res = await post(`vehiclestatus/JobProgressSetVehicleArrivedStatus`, data, { useAuthToken: true });
    return res;
};

export const postJobProgressSetReBookingStatus = async (data) => {
    let res = await post(`VehicleStatus/JobProgressSetReBookingStatus`, data, { useAuthToken: true });
    return res;
};

export const postJobProgressInWorkshop = async (data) => {
    let res = await post(`VehicleStatus/JobProgressInWorkshop`, data, { useAuthToken: true });
    return res;
};

export const postJobProgressWaitingSubWork = async (data) => {
    let res = await post(`VehicleStatus/JobProgressWaitingSubWorkVM`, data, { useAuthToken: true });
    return res;
};

export const postJobProgressAWaitingPart = async (data) => {
    let res = await post(`VehicleStatus/JobProgressAWaitingPart`, data, { useAuthToken: true });
    return res;
};

export const postJobProgressAwaitingAuthority = async (data) => {
    let res = await post(`VehicleStatus/JobProgressAwaitingAuthority`, data, { useAuthToken: true });
    return res;
};

export const postJobProgressWorkCompleted = async (data) => {
    let res = await post(`VehicleStatus/JobProgressWorkCompleted`, data, { useAuthToken: true });
    return res;
};

export const postJobProgressChekedOut = async (data) => {
    let res = await post(`VehicleStatus/JobProgressChekedOut`, data, { useAuthToken: true });
    return res;
};

export const postJobProgressApprovedByCustomer = async (data) => {
    let res = await post(`VehicleStatus/JobProgressApprovedByCustomer`, data, { useAuthToken: true });
    return res;
};

export const postJobProgressAWaitingQC = async (data) => {
    let res = await post(`VehicleStatus/JobProgressAWaitingQC`, data, { useAuthToken: true });
    return res;
};

export const postJobProgressSetCollectedStatus = async (data) => {
    let res = await post(`VehicleStatus/JobProgressSetCollectedStatus`, data, { useAuthToken: true });
    return res;
};

export const postJobProgressCustomerInformed = async (data) => {
    let res = await post(`VehicleStatus/JobProgressCustomerInformed`, data, { useAuthToken: true });
    return res;
};

export const postUpdateVehicleStatusToRebooked = async (data) => {
    let res = await post(`VehicleStatus/UpdateVehicleStatusToRebooked `, data, { useAuthToken: true });
    return res;
};

export const postJobProgressOtherStatus = async (data) => {
    let res = await post(`VehicleStatus/JobProgressOtherStatus`, data, { useAuthToken: true });
    return res;
};

export const postBookingRequested = async (data) => {
    let res = await post(`CommunicatorBookingRequest/BookingRequested`, data, { useAuthToken: true });
    return res;
};

export const getCommunicatorBookingRequestScreenDropDown = (wipNo) => {
    let res = get(`CommunicatorBookingRequest/ScreenDropDown`, { useAuthToken: true });
    return res;
};

export const getBookingRequest = (id) => {
    let res = get(`CommunicatorBookingRequest/BookingRequest/${id}`, { useAuthToken: true });
    return res;
};

export const postBookingReSchedule = async (data) => {
    let res = await post(`CommunicatorBookingRequest/BookingReSchedule`, data, { useAuthToken: true });
    return res;
};
export const postBookingCancel = async (data) => {
    let res = await post(`CommunicatorBookingRequest/BookingCancel`, data, { useAuthToken: true });
    return res;
};
export const postBookingAccept = async (data) => {
    let res = await post(`CommunicatorBookingRequest/BookingAccept`, data, { useAuthToken: true });
    return res;
};

export const postBookingRequestStatus = async (data) => {
    let res = await post(`CommunicatorBookingRequest/UpdateBookingRequestStatus`, data, { useAuthToken: true });
    return res;
};
export const getCommunicatorsDropdown = async () => {
    let res = await get('Communicators/ScreenDropdown', { useAuthToken: true });
    return res;
};
export const getCommunicatorDropdownList = async () => {
    return (await getCommunicatorsDropdown()).data;
};
