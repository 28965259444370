import { get, post } from './http-calls';
import moment from 'moment';

export const nicoleDownloadFileWithExt = async (res, fileExt, fileName, hideTimeStamp) => {
    if (res.status == 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
        link.id = 'tempDownloadPDFLink';
        link.href = url;
        link.setAttribute('download', `${fileName}${hideTimeStamp ? '' : moment().format('DD-MMM-YYYY HH:mm')}.${fileExt}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return { message: 'File save successfully', success: true };
    } else {
        return { message: 'File not found', success: false };
    }
};

export let getReportingNotes = async (smn) => {
    let data = await get(`AgedMeasures/GetReportingAccountQuery?ReportingAccountQuerySystemMatchNumber=${smn}`, { useAuthToken: true });
    return data;
};
export let getHoverReportingNotes = async (smn) => {
    let data = await get(`AgedMeasures/GetReportingAccountQueryNotes?smn=10846442`, { useAuthToken: true });
    return data;
};

export let getReportingWIPLogs = async (headerRecordID) => {
    let data = await get(`agedMeasures/GetReportingWIPLogs?headerRecordID=${headerRecordID}`, { useAuthToken: true });
    return data;
};
export let addReportingWIPLogs = async (data) => {
    let res = await post(`AgedMeasures/SaveReportingWIPLog`, data, {
        useAuthToken: true
    });
    return res;
};

export const addReportingNotes = async (data) => {
    let res = await post(`AgedMeasures/SaveReportingAccountQuery`, data, {
        useAuthToken: true
    });
    return res;
};

export let getWeeklyFlashReport = async () => {
    let data = await get(`Docs/WeeklyFlashReport`, { useAuthToken: true });
    return data;
};

export let getMotHistory = async (regNo, FranchiseCode) => {
    let data = await get(`services/CheckMotHistory?regNo=${regNo.replace(/\s/g, '')}&FranchiseCode=${FranchiseCode}`, { useAuthToken: true });
    return data;
};

export let getAgedMeasuresViewDocument = async (InvoiceFileName) => {
    let data = await get(`AgedMeasures/ViewDocument?InvoiceFileName=${InvoiceFileName}`, { useAuthToken: true, responseType: 'blob' });
    return data;
};

export let getReportingBudgetScreenDropDown = async (smn) => {
    let data = await get(`ReportingBudget/ScreenDropDown`, {
        useAuthToken: true
    });
    return data;
};

export let getReportingBudget = async (Type, Year) => {
    let data = await get(`ReportingBudget?Type=${Type}&Year=${Year}`, {
        useAuthToken: true
    });
    return data;
};

export const addReportingBudget = async (dataList) => {
    let prom = [];
    dataList.forEach((o) => {
        prom.push(post(`ReportingBudget`, o, { useAuthToken: true }));
    });

    return Promise.all(prom)
        .then((ress) => {
            return {
                success: true,
                message: 'Budget Save Successfully.'
            };
        })
        .catch((ex) => {
            console.error(ex);
            return {
                success: false,
                message: 'Failed To Save Budget.  Please try again.'
            };
        });

    // let res = await post(`ReportingBudget`, data, { useAuthToken: true });
    // return res;
};

export const getUsedStockValuation = async (data) => {
    let res = await get(`ProfitLoss/GetUsedStockValuationDashBoardData?Type=${data}`, { useAuthToken: true });
    return res;
};

export const postUsedVehicleStockGetExcelFile = async (data, fileName) => {
    let res = await post(`ProfitLoss/UsedVehicleStock/GetExcelFile`, data, {
        responseType: 'blob',
        returnOrgRes: true,
        useAuthToken: true
    });
    nicoleDownloadFileWithExt(res, 'xlsx', fileName);
};

export const getVehicleProfileListDropDown = async (data) => {
    let res = await get(`AgedMeasures/ReportingGetProgressCodes`, {
        useAuthToken: true
    });
    return res;
};

export const postUsedVehicleStockValuation = async (data) => {
    let res = await post(`ProfitLoss/GetUsedVehicleStockDashBoardData`, data, {
        useAuthToken: true
    });
    return res;
};

export const getAgedStockValuation = async (data) => {
    let res = await get(`ProfitLoss/GetAgedStockValuationDashBoardData`, {
        useAuthToken: true
    });
    return res;
};

export const postOveragePriorityList = async (data) => {
    let res = await post(`ProfitLoss/GetOveragePriorityListDashBoardData`, data, {
        useAuthToken: true
    });
    return res;
};

export const postOveragePriorityGetExcelFile = async (data, fileName) => {
    let res = await post(`ProfitLoss/OveragePriority/GetExcelFile`, data, {
        responseType: 'blob',
        returnOrgRes: true,
        useAuthToken: true
    });
    nicoleDownloadFileWithExt(res, 'xlsx', fileName);
};

export const getReprtingDashboard = async () => {
    let res = await get(`ReportingBudget/ReportingInternalDashboard`, {
        useAuthToken: true
    });
    return res;
};

export const getReprtingAfterSaleDashboard = async () => {
    let res = await get(`ReportingBudget/ReportingInternalAfterSalesDashboard`, {
        useAuthToken: true
    });
    return res;
};

export const getReportingInternalHighLevelDashboard = async () => {
    let res = await get(`ReportingBudget/ReportingInternalHighLevelDashboard`, {
        useAuthToken: true
    });
    return res;
};

export const addReportingVehicleBudget = async (data) => {
    let res = await post(`ReportingVehicleBudget`, data, {
        useAuthToken: true
    });
    return res;
};

export const addSalesCategoryTargetBudget = async (data) => {
    let res = await post(`SalesCategoryTarget`, data, {
        useAuthToken: true
    });
    return res;
};

export const addReportingVehicleInternalBudget = async (data) => {
    let res = await post(`ReportingVehicleInternalTarget`, data, {
        useAuthToken: true
    });
    return res;
};

export let getNewUsedVehicleReportingBudgetScreenDropDown = async (TargetType) => {
    let data = await get(`ReportingVehicleBudget/ScreenDropDown?TargetType=${TargetType}`, {
        useAuthToken: true
    });
    return data;
};

export let getNewUsedSalesCategoryudgetScreenDropDown = async (TargetType) => {
    let data = await get(`SalesCategoryTarget/ScreenDropDown?SalesCategory=${TargetType}`, {
        useAuthToken: true
    });
    return data;
};

export let getNewUsedSalesCategoryudgetOperatorDetailsDropDown = async (TargetType, CategoryID) => {
    let data = await get(`SalesCategoryTarget/OperatorDetailsDropDown?SalesCategory=${TargetType}&CategoryID=${CategoryID}`, {
        useAuthToken: true
    });
    return data;
};
export let getNewUsedVehicleReportingInternalBudgetScreenDropDown = async (TargetType, CategoryID) => {
    let data = await get(`ReportingVehicleInternalTarget/ScreenDropDown?TargetType=${TargetType}&CategoryID=${CategoryID}`, {
        useAuthToken: true
    });
    return data;
};

export let getNewUsedSalesCategoryTargetBudget = async (Type, Year, TargetTypeID, CategoryID) => {
    let data = await get(`SalesCategoryTarget?Type=${Type}&Year=${Year}&TargetTypeID=${TargetTypeID}&CategoryID=${CategoryID}`, {
        useAuthToken: true
    });
    return data;
};

export let getNewUsedVehicleReportingBudget = async (Type, Year, TargetTypeID, CategoryID) => {
    let data = await get(`ReportingVehicleBudget?Type=${Type}&Year=${Year}&TargetTypeID=${TargetTypeID}&CategoryID=${CategoryID}`, {
        useAuthToken: true
    });
    return data;
};
export let getNewUsedVehicleInternalReportingBudget = async (Type, Year, TargetTypeID, CategoryID) => {
    let data = await get(`ReportingVehicleInternalTarget?Type=${Type}&Year=${Year}&TargetTypeID=${TargetTypeID}&CategoryID=${CategoryID}`, {
        useAuthToken: true
    });
    return data;
};

export let getReportingGetVehicleDetails = async (vehicleNumber) => {
    let data = await get(`Vehicle/ReportingGetVehicleDetails?vehicleNumber=${vehicleNumber}`, {
        useAuthToken: true
    });
    return data;
};

export let getReportingGetVehiceMOTDue = async (startDate, endDate) => {
    let data = await get(`Vehicle/ReportingGetVehiceMOTDue?StartDate=${startDate}&EndDate=${endDate}`, {
        useAuthToken: true
    });
    return data;
};

export let getInvoicedHoursCalender = async (startDate, endDate, BranchID, fn) => {
    let data = await get(
        `ReportingCalendar/InvoicedHoursCalender?StartDate=${startDate}&EndDate=${endDate}${BranchID ? `&BranchID=${BranchID}` : ''}${fn ? `&fn=${fn}` : ''}`,
        {
            useAuthToken: true
        }
    );
    return data;
};
export let getPartsCalender = async (startDate, endDate, BranchID) => {
    let data = await get(`ReportingCalendar/PartsCalender?StartDate=${startDate}&EndDate=${endDate}&BranchID=${BranchID}`, {
        useAuthToken: true
    });
    return data;
};

export let getInvoiceBranch = async (startDate, endDate, BranchID) => {
    let data = await get(`ReportingCalendar/Branches_Dropdown`, {
        useAuthToken: true
    });
    return data;
};

export let getReportingGetVehiceServices = async (startDate, endDate) => {
    let data = await get(`Vehicle/ReportingGetVehiceServices?StartDate=${startDate}&EndDate=${endDate}`, {
        useAuthToken: true
    });
    return data;
};

export let getReportingGetSafetyCheckDue = async (startDate, endDate) => {
    let data = await get(`Vehicle/ReportingGetSafetyCheckDue?StartDate=${startDate}&EndDate=${endDate}`, {
        useAuthToken: true
    });
    return data;
};

export let getReportingFPInterestRates = async (Year) => {
    let data = await get(`FPInterestRates?Year=${Year}`, {
        useAuthToken: true
    });
    return data;
};
export let getFPProviderList = async () => {
    let data = await get(`FinanceProviders/FP/List`, {
        useAuthToken: true
    });
    return data;
};

export let getFPProviderDropdownList = async () => {
    let data = await get(`FinanceProviders/Franchise/Dropdown`, {
        useAuthToken: true
    });
    return data;
};

export let addupdateFPInterestRates = async (data) => {
    let res = await post(`FPInterestRates`, data, {
        useAuthToken: true
    });
    return res;
};

export let getFPProviderModelById = async (ID) => {
    let data = await get(`FinanceProviders/FP/${ID}`, {
        useAuthToken: true
    });
    return data;
};

export let addupdateFinanceProvidersModel = async (data) => {
    let res = await post(`FinanceProviders`, data, {
        useAuthToken: true
    });
    return res;
};
