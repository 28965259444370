import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deleteJobOcrDocument, moveDocToOtherJob, setAsCurrentDocument } from '../../../Core/Service/workflowJobDocument-service';
import { Visibility, Delete, Reply } from '@material-ui/icons';
import { Checkbox, CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { find } from 'lodash';
import { SecondaryButton } from '../../../Core/FormInput';
import { ScreenContext } from './WorkflowJob.Provider';
import DeleteConfirm from './more/Delete';
import OtherJobSelector from './more/OtherJobDocMovement';
import { AppStorage } from '../../../Core/Service/storage-service';
import AppContext from '../../../App/AppContext';

const HistoryTableRow = ({ item, rowLevelAction, setDocAsNextCurrent }) => {
    const { showToast, userRoles } = useContext(AppContext);

    const isAuditor = userRoles.includes('auditor');

    const showDoc = () => {
        rowLevelAction({ type: 'VIEW', item: item });
    };

    const deleteDoc = () => {
        rowLevelAction({ type: 'DELETE', item: item });
    };

    const forwardDoc = () => {
        rowLevelAction({ type: 'FORWARD', item: item });
    };

    return (
        <tr className={`row${item.isCurrent ? ' is-current-doc' : ''}`}>
            <td className="is-current">
                <Checkbox checked={item.isCurrent} color={item.isCurrent ? 'primary' : ''} onClick={() => setDocAsNextCurrent(item.docName)} />
            </td>
            <td className="time-type">{moment(item.createdAt).format('LLL')}</td>
            <td className="created_By">{item.createdBy}</td>
            <td className="actions">
                <div className="icon-button-group">
                    <Tooltip title="View">
                        <IconButton color="primary" component="span" onClick={showDoc} disabled={item.isloading == false ? true : false}>
                            {item.isloading ? (
                                <Grid>
                                    <Grid>
                                        <CircularProgress size={20} />
                                        &nbsp;
                                    </Grid>
                                </Grid>
                            ) : (
                                <Visibility disabled={item.isloading == false ? true : false} />
                            )}
                        </IconButton>
                    </Tooltip>
                    {!isAuditor && AppStorage.getCanDeleteDocuments() && (
                        <Tooltip title="Wrong Document? Delete it.">
                            <IconButton color="secondary" component="span" onClick={deleteDoc}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    )}
                    {!isAuditor && (
                        <Tooltip title="Move to other Job">
                            <IconButton color="green" component="span" className="rotate-y-180" onClick={forwardDoc}>
                                <Reply />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </td>
        </tr>
    );
};

const HistoryTable = ({ rec, loading, rowLevelAction, setDocAsNextCurrent }) => {
    return (
        <>
            {/* <table className="ocrDoc-history-header">
                <thead>
                    <tr className="header">
                        <th className="is-current">Is Current</th>
                        <th className="time-type">Created At</th>
                        <th className="">Created By</th>
                        <th className="actions">Action</th>
                    </tr>
                </thead>
            </table> */}
            <div className="body-table-container custom-scroll">
                <table className="ocrDoc-history-body">
                    <thead>
                        <tr className="header">
                            <th className="is-current">Is Current</th>
                            <th className="time-type">Created At</th>
                            <th className="created_By">Created By</th>
                            <th className="actions">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rec.map((r, i) => (
                            <HistoryTableRow key={i} item={r} rowLevelAction={rowLevelAction} setDocAsNextCurrent={setDocAsNextCurrent} />
                        ))}
                        {rec.length === 0 && (
                            <tr>
                                <td colSpan="4">{loading ? 'Loading...' : 'No record found.'}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

const OcrDocHistory = (props) => {
    let setDocumentWithOcrDocId, getDocumentHistory;
    const screenContext = useContext(ScreenContext);
    ({ setDocumentWithOcrDocId, getDocumentHistory } = screenContext ? screenContext : props);

    const [state, setState] = useState({
        loading: true,
        docHistory: [],
        orgCurrentDoc: undefined,
        nextCurrentDoc: undefined,
        showDeleteDialog: false,
        showDocToOtherModal: false,
        itemToDeleteOrMove: undefined
    });

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const pullHistoryAndSetState = () => {
        return getDocumentHistory(props.jobId, props.ocrDocId)
            .then((res) => {
                if (res.success) {
                    //let rec = res.data.sort((a,b)=>moment(a.createdAt) < moment(b.createdAt) ? 1 : -1);
                    let rec = res.data;
                    let orgCurrentDoc = find(rec, (r) => r.isCurrent)?.docName;
                    props.updateState({ selectedDocHistory: rec });
                    setState((st) => ({ ...st, docHistory: rec, orgCurrentDoc, nextCurrentDoc: orgCurrentDoc }));
                }
            })
            .finally(() => {
                setState((st) => ({ ...st, loading: false }));
            });
    };

    useEffect(() => {
        pullHistoryAndSetState();
    }, [props.jobId, props.ocrDocId]);

    useEffect(() => {
        if (props.ocrDocManage == 'removeloading') {
            removeIsDocLoading();
        }
    }, [props.ocrDocManage]);

    const setWorkflowDocasLoading = (docName) => {
        const updatedWorkflowDocs = state.docHistory.map((doc) => {
            if (doc.docName == docName) {
                return { ...doc, isloading: true };
            } else {
                return { ...doc, isloading: false };
            }
        });

        setState((dt) => {
            const newSt = {
                ...dt
            };
            newSt.docHistory = updatedWorkflowDocs;
            return newSt;
        });
    };
    const removeIsDocLoading = () => {
        const updatedWorkflowDocs = state.docHistory.map((doc) => {
            const { isloading, ...rest } = doc;
            return rest;
        });
        setState((dt) => {
            const newSt = {
                ...dt
            };
            newSt.docHistory = updatedWorkflowDocs;
            return newSt;
        });
    };
    const showDoc = (docName) => {
        props.showDoc && props.showDoc(props.ocrDocId, docName, false, true);
        setWorkflowDocasLoading(docName);
    };

    const rowLevelAction = (e) => {
        switch (e.type) {
            case 'VIEW':
                showDoc(e.item.docName);
                break;
            case 'DELETE':
                setState((st) => ({ ...st, showDeleteDialog: true, itemToDeleteOrMove: e.item }));
                break;
            case 'FORWARD':
                setState((st) => ({ ...st, showDocToOtherModal: true, itemToDeleteOrMove: e.item }));
                break;
        }
    };

    const hideDialog = (propName) => {
        setState((st) => ({ ...st, [propName]: false, itemToDeleteOrMove: undefined }));
    };

    useEffect(() => {
        if (state.itemToDeleteOrMove) {
            if (state.docHistory.length === 0) {
                handleClose();
            } else {
                setState((st) => ({ ...st, itemToDeleteOrMove: undefined }));
            }
            let docName = state.orgCurrentDoc;
            setDocumentWithOcrDocId(props.ocrDocId, docName);
            showDoc(docName);
        }
    }, [state.docHistory]);

    const confirmDelete = async () => {
        setState((st) => ({ ...st, loading: true, showDeleteDialog: false }));
        const res = await deleteJobOcrDocument(props.jobId, props.ocrDocId, state.itemToDeleteOrMove.id, state.itemToDeleteOrMove.isCurrent);
        props.showToast(res.message);
        if (res.success) {
            pullHistoryAndSetState();
        }
    };

    const setDocAsNextCurrent = (docName) => {
        setState((st) => {
            let newSt = { ...st };
            let doc = newSt.docHistory.find((d) => d.docName === docName);
            if (doc) {
                newSt.docHistory.forEach((d) => (d.isCurrent = false));
                doc.isCurrent = true;
                newSt.nextCurrentDoc = docName;
                newSt.docHistory = [...newSt.docHistory];
            }
            return newSt;
        });
    };

    const saveSelectedAsCurrent = async () => {
        const { id, docName } = state.docHistory.find((d) => d.isCurrent);
        const res = await setAsCurrentDocument(props.jobId, props.ocrDocId, id);
        props.showToast(res.message);
        if (res.success) {
            pullHistoryAndSetState().then(() => {
                setDocumentWithOcrDocId(props.ocrDocId, docName);
                showDoc(docName);
            });
        }
    };

    const moveToOtherJob = async (otherJobId) => {
        setState((st) => ({ ...st, loading: true, showDocToOtherModal: false }));
        const res = await moveDocToOtherJob(props.jobId, otherJobId, props.ocrDocId, state.itemToDeleteOrMove.id, state.itemToDeleteOrMove.isCurrent);
        props.showToast(res.message);
        if (res.success) {
            pullHistoryAndSetState();
        }
    };

    return (
        <>
            <Dialog
                open={true}
                onClose={handleClose}
                maxWidth="md"
                className={`dialog-custom ${props.adminView ? 'ocrDocument-history-modal-admin' : 'ocrDocument-history-modal'}`}
            >
                <DialogTitle>
                    Document History
                    <div className="title-header-actions">
                        <span className="close-icon" onClick={handleClose}>
                            &times;
                        </span>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="ocrDoc-history">
                        <HistoryTable
                            rec={state.docHistory}
                            loading={state.loading}
                            rowLevelAction={rowLevelAction}
                            setDocAsNextCurrent={setDocAsNextCurrent}
                        />
                    </div>
                    <SecondaryButton className="mt-10 pull-right" disabled={state.orgCurrentDoc === state.nextCurrentDoc} onClick={saveSelectedAsCurrent}>
                        Make Current Document
                    </SecondaryButton>
                </DialogContent>
            </Dialog>
            {state.showDeleteDialog && <DeleteConfirm onClose={() => hideDialog('showDeleteDialog')} onConfirm={confirmDelete} />}
            {state.showDocToOtherModal && (
                <OtherJobSelector
                    headerId={props.jobId}
                    showToast={props.showToast}
                    onClose={() => hideDialog('showDocToOtherModal')}
                    onProcess={moveToOtherJob}
                />
            )}
        </>
    );
};

export default OcrDocHistory;
