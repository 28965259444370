import React, { useContext, useEffect, useRef, useState } from 'react';
import { ButtonGroup, Grid, IconButton, LinearProgress, Tooltip, Badge, Chip, Avatar, MenuItem, Menu, Icon, Button } from '@material-ui/core';
import { BasicButtonGroup } from '../../../Core/ButtonGroup';
import WorkflowStep, { StepWebFormModal } from './WorkflowStep';
import { DefaultButton, PrimaryButton, SecondaryButton } from '../../../Core/FormInput';
import AppContext from '../../../App/AppContext';
import { downloadDocuments, getDocumentByName } from '../../../Core/Service/workflowJobDocument-service';
import { WorkflowJobDetailsProvider as StateProvider, ScreenContext, MarkingType, FixedImage } from './WorkflowJob.Provider';
import JobNotesModal from './JobNotes';
import WorkflowMovementModal from './WorkflowMovement';
import ReturnReasonWorkflow from './ReturnToWorkflow';
import OcrDocHistory from './WorkflowOcrDocHistoryModal';
import MailDocModal from './more/MailDoc';
import AllDocModal from './more/AllDocModal';
import WarningMsg from './WarningMsg';
import ReturnReasonHistoryModal from './ReturnReasonHistory';
import HistoryIcon from '@material-ui/icons/History';
import doc from './doc.png';
import './workflow-job.scss';
import {
    Reply,
    History,
    ArrowBack,
    AlternateEmailOutlined,
    Explicit,
    CalendarToday,
    BorderColor,
    TextFields,
    ArrowRight,
    ArrowLeft,
    Person
} from '@material-ui/icons';
import { SpeakerNotes, Timeline, Done, DoneAll, PhotoLibraryOutlined, Close, ArrowDropDown, FiberManualRecord, Image } from '@material-ui/icons';
import DocStamps from './more/Stamps';
import { getDocumentStamps } from '../../../Core/Service/workflowJobsDocumentStamp-service';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import HavePendingMarking from './more/ConfirmationOnPendingMarking.jsx';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import ShowBarCode from './BarCode';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import CustomerNotes from './CustomerNotes';
import AddEditSiteVehicle from '../Communicator/SiteVehicles/addEditSiteVehicle';
import LogHistory from '../Communicator/OpenActionLogs/logHistory';
import BuildIcon from '@material-ui/icons/Build';
import UpdateIcon from '@material-ui/icons/Update';
import { AppStorage } from '../../../Core/Service/storage-service';
import { useHistory } from 'react-router-dom';
import HeaderDetailsModal from './more/headerDetailsModal';
import InfoIcon from '@material-ui/icons/Info';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { fabric } from 'fabric';
import { useCallback } from 'react';
import _, { some } from 'lodash';
import JobAssignedUser from './JobAssignedUser';
import PeopleIcon from '@material-ui/icons/People';
import moment from 'moment';
import DownloadDocModal from './more/DownloadDoc';

function scrollbarVisible(element) {
    return element?.scrollWidth > element?.clientWidth;
}

const scrollNext = (id) => {
    document.getElementById('1WorkFlow-Btn').scrollBy({ left: 100, top: 0, behavior: 'smooth' });
};

const scrollPrev = (id) => {
    document.getElementById('1WorkFlow-Btn').scrollBy({ left: -100, top: 0, behavior: 'smooth' });
};

const WorkflowJobDetails = (props) => {
    const [WindowWidths, WindowHeights] = useWindowSize();

    let WorkFlowBtnElementscrollWidth = document.getElementById('1WorkFlow-Btn');

    useEffect(() => {
        updateState({
            isShowScrollArrow: scrollbarVisible(WorkFlowBtnElementscrollWidth)
        });
    }, [WindowWidths, WorkFlowBtnElementscrollWidth?.scrollWidth]);
    const docContainer = useRef(null);
    const stampContainer = useRef(null);
    const canvasRef = useRef(null);
    const fabricLoadedRef = useRef(false);
    const [currentPageNo, setPageNo] = useState(0);
    const { showToast, canOverrideMandatorySteps, userRoles, isTopNav } = useContext(AppContext);
    const portalSettings = AppStorage.getPortalSetting();
    const isCommunicatorAllowed = AppStorage.getCommunicatorAllowed() && portalSettings.portalSettingCommunicatorEnabled;
    const {
        screenState,
        updateState,
        state,
        markingState,
        saveCanvasDataForPageNo,
        hasAnythingToSave,
        startMarkingMode,
        stopMarkingMode,
        markingTypeSelection,
        saveStampData,
        setLoadedStamps,
        addDocumentImagesData,
        hasPrev,
        hasNext,
        workflowData,
        dropDownValueSelected,
        showNotes,
        hideNotesModal,
        notesAdded,
        showHistory,
        showReturnReasonHistory,
        hideReturnReasonHistory,
        hideHistoryModal,
        showReturnReason,
        hideReturnReason,
        returnProcessed,
        tryToSaveWorkflowData,
        showOcrDocHistoryModal,
        hideOcrDocHistoryModal,
        rotateDoc,
        showMail,
        hideMail,
        showDownload,
        hideDownload,
        showAllDoc,
        hideAllDoc,
        getDocumentFromOcrDocId,
        hideWarningMsg,
        tabView,
        tabSize,
        getDocumentHistory,
        onBarCodeClick,
        onBarCodeClose,
        onJobAssignedUserClick,
        onJobAssignedUserClose,
        handleCustomerNotes,
        handleHistoryModal,
        closeHistoryModal,
        handleVehicleStatusClose,
        onVehicleStatusClick,
        showHeaderDetailsHandler,
        closeHeaderDetails,
        ParamProcessId,
        ParamInternalTechtechId,
        ParamBranchShortName,
        froms24,
        S24IsUnallocated,
        ParamPartsWorkflowID,
        btnLoader,
        pullDocumentVideoByName
    } = useContext(ScreenContext);
    const { allFilled, isReadonly, documentRotateBy, zoomFactor, docInfoToMail } = screenState;
    const saveBtnEnabled = !isReadonly && (canOverrideMandatorySteps || allFilled);

    const workflowButtonClicked = (btn) => {
        updateState({ selectedWorkflowId: btn.id, currentVisibleDocName: undefined });
    };

    const pullDocument_V2 = useCallback(
        async (docId, docName, pageNo = 1) => {
            if (!stampContainer.current) {
                return;
            }
            let { scrollWidth, scrollHeight } = stampContainer?.current;
            return getDocumentByName(docName, pageNo, scrollWidth > 0 ? scrollWidth : window.screen.width).then((res) => {
                if (pageNo === 1) {
                    setTimeout(() => {
                        let { scrollWidth, scrollHeight } = stampContainer?.current;
                        window.fabricCanvas?.setHeight(scrollHeight);
                        window.fabricCanvas?.setWidth(scrollWidth);
                    }, 500);
                }
                addDocumentImagesData(docId, docName, res.imgBase64Src);
                return res.hasMorePage && pullDocument_V2(docId, docName, pageNo + 1);
            });
        },
        [stampContainer]
    );

    const pullDocument_V1 = async (docId, name, pageNo = 1) => {
        return getDocumentByName(name, pageNo).then((res) => {
            addDocumentImagesData(docId, name, res.imgBase64Src);
            return res.hasMorePage && pullDocument_V1(docId, name, pageNo + 1);
        });
    };
    const loadDocument = async (docId, docName, fromSteps, OcrDoc, isVideo) => {
        if (isVideo) {
            updateState({ docHistoryLoading: true, loadingDoc: true });
            getDocumentHistory(state.jobId, docId).then((res) => {
                if (res.success) {
                    let rec = res.data;
                    updateState({ selectedOcrDocHistoryCount: rec.length, selectedDocHistory: rec, imagesFromUrl: [], videoFromUrl: null });
                }
            });
            pullDocumentVideoByName(docName).then((res) => {
                let rec = res;
                const url = window.URL.createObjectURL(new Blob([rec.data]));
                updateState({
                    loadingDoc: false,
                    isSelectedFilePdf: false,
                    selectedOcrDocName: docName,
                    docHistoryLoading: false,
                    videoFromUrl: url,
                    currentVisibleDocName: docName,
                    selectedOcrDocId: docId
                });
            });
        } else {
            !props.canUseNewStamp && setLoadedStamps([]);
            updateState({ docHistoryLoading: true });
            setPageNo(0);
            getDocumentHistory(state.jobId, docId).then((res) => {
                if (res.success) {
                    let rec = res.data;
                    updateState({ selectedOcrDocHistoryCount: rec.length, selectedDocHistory: rec });
                }
            });

            const isPdf = docName.toLowerCase().trim().endsWith('.pdf');
            updateState({
                loadingDoc: true,
                isSelectedFilePdf: isPdf,
                imagesFromUrl: [],
                videoFromUrl: null,
                currentVisibleDocName: docName,
                selectedOcrDocId: docId
            });

            (props.canUseNewStamp ? pullDocument_V2(docId, docName) : pullDocument_V1(docId, docName)).finally(() => {
                updateState({ loadingDoc: false, docHistoryLoading: false });
            });

            if (!props.canUseNewStamp) {
                getDocumentStamps(state.jobId, docId, docName).then((res) => {
                    const { success, data } = res;
                    if (success) {
                        const allSavedStamps = data?.res?.allStamps;
                        setLoadedStamps(allSavedStamps);
                    }
                });
            }
        }
    };

    const downloadDocument = async (docId, docName, fromSteps = false, OcrDoc = false, isVideo = false) => {
        rotateDoc(0);
        props.canUseNewStamp && stopMarkingMode();
        if (docName) {
            if (state.selectedOcrDocId !== docId || state.selectedOcrDocName !== docName) {
                loadDocument(docId, docName, fromSteps, OcrDoc, isVideo);
            }
        } else {
            updateState({ isSelectedFilePdf: false, imagesFromUrl: [] });
        }
    };
    const showDoc = !state.tabView || tabSize > 1260;
    const showStep = state.tabView || tabSize > 1260;

    const showAlldocState = (showdoc, id, IsVideo) => {
        updateState({ tabView: false });
        downloadDocument(showdoc, id, false, false, IsVideo);
    };

    const handleDocumentAction = (e) => {
        const { eventType, doc, ocrDocumentId, step } = e;
        switch (eventType) {
            case 'VIEW':
                downloadDocument(ocrDocumentId, doc?.docName, true, false, doc?.IsVideo);
                break;
            case 'MAIL':
                showMail({ ocrDocumentName: step.ocrDocumentName, docName: doc.docName, ocrDocIdToMail: ocrDocumentId });
                break;
            default:
                showToast(`Event Type: ${eventType} is not implement yet.`);
        }
    };
    const HandelMailDocument = (e) => {
        showMail({ ocrDocumentName: e.ocrDocName, docName: e.docName, ocrDocIdToMail: e.ocrDocId });
    };
    const DownloadOcrDocument = async (e, isMultiVersion) => {
        showDownload({
            ocrDocName: e.docName,
            ocrDocId: e.ocrDocId,
            // SendAllVersions: isMultiVersion,
            headerId: state.jobId,
            docName: `${e.ocrDocName}`,
            createdAt: moment(e.createdAt).format('DD-MMM-YYYY HH:mm')
        });
    };

    const displayDoc = () => {
        if (state.videoFromUrl) {
            let width = docContainer?.current?.clientWidth;
            width = parseInt(width) * zoomFactor;
            let styleObj = {};
            if (documentRotateBy === 0 || documentRotateBy === 180) {
                styleObj.width = `calc(${width}px - 6px)`;
            } else {
                styleObj.height = `calc(${width}px - 6px)`;
            }
            return (
                <video controls controlsList="nodownload" style={styleObj}>
                    <source src={state.videoFromUrl} type="video/mp4" />
                </video>
            );
        } else if (state.imagesFromUrl.length === 0) {
            return <img src={doc} alt="No document selected" className="sample-doc" />;
        } else {
            if (props.canUseNewStamp) {
                return <img alt="Document's" src={state.imagesFromUrl[currentPageNo]} className={`shown-doc deg-${documentRotateBy}`} />;
            } else {
                let width = docContainer?.current?.clientWidth;
                width = parseInt(width) * zoomFactor;
                let styleObj = {};
                if (documentRotateBy === 0 || documentRotateBy === 180) {
                    styleObj.width = `calc(${width}px - 6px)`;
                } else {
                    styleObj.height = `calc(${width}px - 6px)`;
                }

                return state.imagesFromUrl.map((imgBase64, i) => (
                    <img key={i} alt="Document's" src={imgBase64} className={`shown-doc deg-${documentRotateBy}`} style={styleObj} />
                ));
            }
        }
    };

    const saveWorkflowData = async (markComplete) => {
        const res = await tryToSaveWorkflowData(markComplete);
        showToast(res.message);
    };

    let timeout;
    let updateCanvasDataToState = useCallback(() => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            saveCanvasDataForPageNo(
                currentPageNo,
                window.fabricCanvas.toDatalessJSON(),
                window.fabricCanvas.toDataURL(),
                window.fabricCanvas.getActiveObjects().length > 0
            );
        }, 100);
    }, [currentPageNo]);

    useEffect(() => {
        if (!canvasRef.current) return;
        if (!fabricLoadedRef.current) {
            window.fabricCanvas = new fabric.Canvas('main-canvas');
            window.fabricCanvas.freeDrawingBrush.width = 1.4;
            window.fabricCanvas.freeDrawingBrush.color = 'red';
            fabricLoadedRef.current = true;
        }
        if (fabricLoadedRef.current) {
            window.fabricCanvas.on('object:added', updateCanvasDataToState);
            window.fabricCanvas.on('object:removed', updateCanvasDataToState);
            window.fabricCanvas.on('object:modified', updateCanvasDataToState);
            window.fabricCanvas.on('selection:created', updateCanvasDataToState);
            window.fabricCanvas.on('selection:cleared', updateCanvasDataToState);

            return () => {
                window.fabricCanvas.off('object:added', updateCanvasDataToState);
                window.fabricCanvas.off('object:removed', updateCanvasDataToState);
                window.fabricCanvas.off('object:modified', updateCanvasDataToState);
                window.fabricCanvas.off('selection:created', updateCanvasDataToState);
                window.fabricCanvas.off('selection:cleared', updateCanvasDataToState);
            };
        }
    }, [canvasRef.current, updateCanvasDataToState]);

    useEffect(() => {
        if (!window.fabricCanvas) {
            return;
        }
        if (markingState.markingType === MarkingType.FreeDrawing) {
            window.fabricCanvas.isDrawingMode = true;
        } else {
            window.fabricCanvas.isDrawingMode = false;
        }
    }, [markingState.markingType]);

    useEffect(() => {
        updateState({ currentVisibleDocName: undefined }); //clicking on search by wipno to set currentVisibleDocName undefined
    }, [state.jobId]);

    useEffect(() => {
        if (!state.currentVisibleDocName && state.autoLoadDocuments) {
            setTimeout(() => {
                state.workflowSteps.find((st) => {
                    const ocrDoc = getDocumentFromOcrDocId(st.ocrDocumentId);
                    if (ocrDoc && ocrDoc.docName) {
                        handleDocumentAction({
                            eventType: 'VIEW',
                            stepId: st.stepId,
                            ocrDocumentId: st.ocrDocumentId,
                            doc: ocrDoc,
                            st
                        });
                        return true;
                    } else return false;
                });
            }, 1000);
        } else {
            updateState({ selectedOcrDocHistoryCount: 0, selectedDocHistory: [], isSelectedFilePdf: false, imagesFromUrl: [], docHistoryLoading: false });
        }
    }, [state.workflowSteps, state.jobId]);

    const NumberWithPound = (params) => {
        const val = +params;
        return <>{val ? <>£{val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}</>;
    };
    const isTechnician = userRoles.includes('technician');
    const isInternal = userRoles.includes('internal');
    const isCustomer = userRoles.includes('customer');
    const isAuditor = userRoles.includes('auditor');
    const haveSomethingToSave = props.canUseNewStamp ? hasAnythingToSave() : markingState.stamps.some((c) => c.isNew);

    const hideLeftButtonController = () => {
        const axButtons = ['Notes', 'History', 'Save', 'SaveAndComplete', 'Return', 'ReturnReasonHistory', 'AllDocuments'];
        let shouldHide = true;
        axButtons.map((axB) => {
            if (shouldShowButton(axB)) {
                shouldHide = false;
            }
        });
        return shouldHide;
    };

    const shouldShowButton = (buttonName) => {
        if (isInternal || isTechnician) {
            // if (buttonName === 'Return') {
            //     return AppStorage.getCanReturnJobsFromLastWorkflow();
            // } else {
            //     return true;
            // }
            return true;
        }
        switch (buttonName) {
            case 'Notes':
                return (isCustomer && portalSettings.canCustomerViewNotesButton) || (isAuditor && portalSettings.canAuditorViewNotesButton);
            case 'History':
                return (isCustomer && portalSettings.canCustomerViewHistoryButton) || (isAuditor && portalSettings.canAuditorViewHistoryButton);
            case 'Save':
                return (isCustomer && portalSettings.canCustomerViewSaveButton) || (isAuditor && portalSettings.canAuditorViewSaveButton);
            case 'SaveAndComplete':
                return (isCustomer && portalSettings.canCustomerViewSaveAndCompleteButton) || (isAuditor && portalSettings.canAuditorViewSaveAndCompleteButton);
            case 'Return':
                return (isCustomer && portalSettings.canCustomerViewReturnButton) || (isAuditor && portalSettings.canAuditorViewReturnButton);
            case 'ReturnReasonHistory':
                return (
                    (isCustomer && portalSettings.canCustomerViewReturnReasonHistoryButton) ||
                    (isAuditor && portalSettings.canAuditorViewReturnReasonHistoryButton)
                );
            case 'AllDocuments':
                return (isCustomer && portalSettings.canCustomerViewAllDocumentsButton) || (isAuditor && portalSettings.canAuditorViewAllDocumentsButton);
            default:
                return false;
        }
    };

    const [showConfirmation, setShowConfirmation] = useState(false);

    const clearCanvasAndStopMarkingMode = () => {
        window.fabricCanvas?.clear();
        stopMarkingMode();
    };
    const checkAndStopMarkingMode = () => {
        if (haveSomethingToSave) {
            setShowConfirmation(true);
        } else {
            props.canUseNewStamp ? clearCanvasAndStopMarkingMode() : stopMarkingMode();
        }
    };

    const getScreenOffset = (isFlag) => {
        if (hideLeftButtonController()) {
            if (isTopNav) {
                return '210px';
            } else {
                return '137px';
            }
        } else {
            if (isTopNav) {
                return '256px';
            } else {
                return '185px';
            }
        }
    };

    const deleteActiveFabricObjects = () => {
        if (window.fabricCanvas) {
            let actvObjs = window.fabricCanvas.getActiveObjects();
            actvObjs.forEach((o) => window.fabricCanvas.remove(o));
        }
    };

    const addActionLogHeaderComp = (ModalTitle) => {
        return (
            <Grid container>
                <Grid item>
                    <span>
                        {ModalTitle}
                        <Chip
                            style={{ marginLeft: 10 }}
                            avatar={
                                <Avatar>
                                    <BuildIcon />
                                </Avatar>
                            }
                            label="History"
                            onClick={handleHistoryModal}
                        />
                    </span>
                </Grid>
            </Grid>
        );
    };

    const saveAndRefresh = async () => {
        let res = await saveStampData();
        console.log('saveAndRefresh', res);

        //need to reload the newly saved document for new type of stamping
        if ((props.canUseNewStamp && res.success) || (res.success && !portalSettings.portalSettingSaveNewDoc)) {
            loadDocument(state.selectedOcrDocId, res.data.newFileName, false, false);
        }
    };

    let history = useHistory();
    let mainWorkflowID = history.location.pathname.split('/')[3];
    let isArchivedJob = mainWorkflowID == 7;

    //below logic to open menu for brush color picker.
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setColor = (color) => () => {
        if (markingState.markingType !== MarkingType.FreeDrawing) {
            markingTypeSelection(null, MarkingType.FreeDrawing);
        }
        window.fabricCanvas.freeDrawingBrush.color = color;
        handleClose();
    };
    const [newState, setNewState] = useState(true);

    let checkisEmptyURL = _.isEmpty(workflowData?.jobBasicDetail.defaultWebForm);
    let buttonLabel = workflowData?.jobBasicDetail.buttonLabel;
    let showDefaultModal = newState && !checkisEmptyURL;

    return (
        <div>
            <BasicButtonGroup
                margin={6}
                className="workflow-buttons"
                selectedId={state.selectedWorkflowId}
                triggerButtonClickOnLoad={false}
                buttons={state.workflows}
                onClick={workflowButtonClicked}
            />

            <div className="workflow-job-details">
                <div
                    className="job-basic-details-container"
                    style={{
                        backgroundColor: isArchivedJob ? '#5a5a5a' : 'white',
                        color: isArchivedJob ? 'white' : 'black'
                    }}
                >
                    <div className="job-basic-details">
                        {/* <span className="backBtn"> */}
                        <Tooltip title="Back">
                            <IconButton
                                style={{ color: isArchivedJob ? 'white' : 'red', padding: 0, margin: '0px 5px' }}
                                component="span"
                                color="Secondary"
                                onClick={() => {
                                    if (isInternal) {
                                        if (ParamInternalTechtechId) {
                                            let searchQuery = `&tid=${ParamInternalTechtechId || ''}&bnm=${ParamBranchShortName}`;
                                            history.push({
                                                pathname: '/techDashgrid',
                                                search: searchQuery,
                                                state: {}
                                            });
                                        } else if (froms24) {
                                            S24IsUnallocated ? history.push('/Service24Jobs/UnallocatedJobs') : history.push('/Service24Jobs/AllJobs');
                                        } else if (ParamProcessId == 3) {
                                            props.history.push(`/PartsByCreator?workflowID=${ParamPartsWorkflowID}&ProcessId=${ParamProcessId}`);
                                        } else {
                                            props.history.push(`/dashgrid`);
                                        }
                                    } else if (isTechnician) {
                                        props.history.push(`/`);
                                    } else {
                                        props.history.goBack();
                                    }
                                }}
                            >
                                <ArrowBack />
                            </IconButton>
                        </Tooltip>
                        {/* </span> */}
                        {!hideLeftButtonController() && (
                            <Tooltip title="Bar Code">
                                <IconButton
                                    component="span"
                                    onClick={onBarCodeClick}
                                    color="Secondary"
                                    style={{ color: isArchivedJob ? 'white' : 'red', marginLeft: '5px', padding: 0, margin: '0px 5px' }}
                                >
                                    <ViewWeekIcon size="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {!hideLeftButtonController() && (
                            <Tooltip title="Assigned User">
                                <IconButton
                                    component="span"
                                    onClick={onJobAssignedUserClick}
                                    color="Secondary"
                                    style={{ color: 'red', marginLeft: '5px', padding: 0, margin: '0px 5px' }}
                                >
                                    <PeopleIcon size="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isCommunicatorAllowed && (
                            <Tooltip title="Vehicle Status">
                                <IconButton
                                    component="span"
                                    onClick={onVehicleStatusClick}
                                    color="Secondary"
                                    style={{ color: isArchivedJob ? 'white' : 'red', marginLeft: '5px', padding: 0, marginLeft: ' 5px' }}
                                >
                                    <UpdateIcon size="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {shouldShowButton('Return') && isArchivedJob && AppStorage.getCanReturnJobsFromLastWorkflow() && (
                            <Tooltip title="Return From Archived">
                                <IconButton
                                    component="span"
                                    onClick={showReturnReason}
                                    color="Secondary"
                                    style={{ color: isArchivedJob ? 'white' : 'red', marginLeft: '5px', padding: 0 }}
                                >
                                    <Reply size="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {hideLeftButtonController() && (
                            <DefaultButton
                                style={{ padding: '3px 11px', minWidth: '45px', marginRight: '5px' }}
                                title="All Documents"
                                disabled={workflowData.workflowDocs.length === 0}
                                onClick={showAllDoc}
                            >
                                <PhotoLibraryOutlined />
                            </DefaultButton>
                        )}
                        {workflowData.jobBasicDetail && (
                            <div className={`${workflowData.jobBasicDetail.stopFlag && 'set-background-red'}`}>
                                <div>
                                    WIP No:&nbsp; <span>{workflowData.jobBasicDetail.wipNumber}</span>
                                </div>
                                {WindowWidths > 800 && (
                                    <div>
                                        Customer Order No:&nbsp; <span>{workflowData.jobBasicDetail.customerOrderNo}</span>
                                    </div>
                                )}
                                {WindowWidths > 800 && (
                                    <React.Fragment>
                                        <div>
                                            Reg No:&nbsp;
                                            <span>
                                                {workflowData.jobBasicDetail.regNo}
                                                <span>( {workflowData.jobBasicDetail.vehicleType} )</span>
                                            </span>
                                        </div>
                                        {!isCustomer && (
                                            <div>
                                                {workflowData.jobBasicDetail.customerInternalNotes ? (
                                                    <>
                                                        Customer:&nbsp;
                                                        <span onClick={() => handleCustomerNotes(true)} style={{ color: 'red', cursor: 'pointer' }}>
                                                            {workflowData.jobBasicDetail.customerName}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        Customer:&nbsp;<span>{workflowData.jobBasicDetail.customerName}</span>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        {!isCustomer && (
                                            <div>
                                                Key Number:&nbsp; <span>{workflowData.jobBasicDetail.keyNumber}</span>
                                            </div>
                                        )}
                                        {!isTechnician && !isCustomer && (
                                            <React.Fragment>
                                                <div>
                                                    Outstanding Payment:&nbsp; <span>{NumberWithPound(workflowData.jobBasicDetail.outstandingPayment)}</span>
                                                </div>
                                                <div>
                                                    Credit Limit:&nbsp; <span>{NumberWithPound(workflowData.jobBasicDetail.creditLimit)}</span>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}

                                {WindowWidths > 800 && workflowData.jobBasicDetail.cashMessage && (
                                    <div className="cash-message">{workflowData.jobBasicDetail.cashMessage}</div>
                                )}
                            </div>
                        )}
                    </div>
                    {WindowWidths < 800 && (
                        <div>
                            <InfoIcon className="grid-info-icon" onClick={showHeaderDetailsHandler} />
                        </div>
                    )}
                </div>
                {WindowWidths < 800 && workflowData.jobBasicDetail.cashMessage && (
                    <div className="cash-message-outside">{workflowData.jobBasicDetail.cashMessage}</div>
                )}
                <Grid container className="grid">
                    <Grid item className="grid-left-section" style={{ display: `${showStep ? 'block' : 'none'}` }}>
                        <div className="steps-container custom-scroll" style={{ height: `calc(100vh - ${getScreenOffset(true)})` }}>
                            {state.workflowSteps.map((s) => (
                                <WorkflowStep
                                    key={s.stepId}
                                    step={s}
                                    jobId={state.jobId}
                                    technicianJobId={state.technicianJobId}
                                    dropdownValue={workflowData.tempMergedData[s.jsonPropertyName]}
                                    onDropdownChange={dropDownValueSelected}
                                    onDocumentAction={handleDocumentAction}
                                    isTechReadonly={state.isTechReadonly}
                                    jobBasicDetail={workflowData.jobBasicDetail}
                                />
                            ))}
                            {showDefaultModal && (
                                <StepWebFormModal
                                    step={{}}
                                    isTechReadonly={state.isTechReadonly}
                                    closeModal={() => setNewState(false)}
                                    jobId={state.jobId}
                                    technicianJobId={state.technicianJobId}
                                    navigate={true}
                                    screenName={workflowData?.jobBasicDetail?.buttonLabel}
                                    url={workflowData?.jobBasicDetail?.defaultWebForm}
                                />
                            )}
                        </div>
                        {!hideLeftButtonController() && (
                            <div className="actionButton-Container">
                                {state.isShowScrollArrow && (
                                    <span onClick={() => scrollPrev()} className="ScrollArrow-Container">
                                        <ChevronLeftIcon />
                                    </span>
                                )}
                                <div className="action-buttons" id="1WorkFlow-Btn">
                                    {shouldShowButton('Notes') && (
                                        <DefaultButton title="Notes" onClick={showNotes} className={workflowData.hasNotes ? 'has-records' : ''}>
                                            <SpeakerNotes />
                                        </DefaultButton>
                                    )}
                                    {shouldShowButton('History') && (
                                        <DefaultButton title="History" onClick={showHistory} className="has-records">
                                            <Timeline />
                                        </DefaultButton>
                                    )}
                                    {shouldShowButton('Save') && (
                                        <PrimaryButton
                                            title="Save"
                                            onClick={() => saveWorkflowData(false)}
                                            isBtnLoader={btnLoader.btn1}
                                            disabled={!workflowData.hasSomethingToSave || screenState.isReadonly}
                                        >
                                            <Done />
                                        </PrimaryButton>
                                    )}
                                    {shouldShowButton('SaveAndComplete') && (
                                        <PrimaryButton
                                            title="Save and Complete"
                                            disabled={!saveBtnEnabled}
                                            isBtnLoader={btnLoader.btn2}
                                            onClick={() => saveWorkflowData(true)}
                                        >
                                            <DoneAll />
                                        </PrimaryButton>
                                    )}
                                    {shouldShowButton('Return') && (
                                        <SecondaryButton title="Return" disabled={screenState.isReadonly} onClick={showReturnReason}>
                                            <Reply />
                                        </SecondaryButton>
                                    )}
                                    {shouldShowButton('ReturnReasonHistory') && (
                                        <DefaultButton title="Return Reason History" onClick={showReturnReasonHistory} className="has-records">
                                            <HistoryIcon />
                                        </DefaultButton>
                                    )}
                                    {shouldShowButton('AllDocuments') && (
                                        <DefaultButton title="All Documents" disabled={workflowData.workflowDocs.length === 0} onClick={showAllDoc}>
                                            <PhotoLibraryOutlined />
                                        </DefaultButton>
                                    )}
                                    {/* <DefaultButton title="Log History">
                                <AssignmentIcon />
                            </DefaultButton> */}
                                </div>
                                {state.isShowScrollArrow && (
                                    <span onClick={() => scrollNext()} className="ScrollArrow-Container">
                                        <ChevronRightIcon />
                                    </span>
                                )}
                            </div>
                        )}
                    </Grid>
                    <Grid item className="grid-right-section dialog-custom" style={{ display: `${showDoc ? 'block' : 'none'}` }}>
                        {tabView && !state.isLoadingDoc && (
                            <div className="document-close-action">
                                <Tooltip title="Close">
                                    <IconButton
                                        component="span"
                                        onClick={() => {
                                            updateState({ tabView: true });
                                        }}
                                    >
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                        {state.imagesFromUrl.length > 0 ? (
                            <>
                                {props.canUseNewStamp && hasPrev(currentPageNo) && (
                                    <div className="doc-page-move go-prev">
                                        <IconButton component="span" color="default" onClick={() => setPageNo(currentPageNo - 1)}>
                                            <ArrowLeft />
                                        </IconButton>
                                    </div>
                                )}
                                {props.canUseNewStamp && hasNext(currentPageNo) && (
                                    <div className="doc-page-move go-next">
                                        <IconButton component="span" color="default" onClick={() => setPageNo(currentPageNo + 1)}>
                                            <ArrowRight />
                                        </IconButton>
                                    </div>
                                )}
                                <div className="document-action">
                                    {/* <Tooltip title="Rotate by 90'">
                                        <IconButton component="span" onClick={() => rotateDoc()}>
                                            <Rotate90DegreesCcw />
                                        </IconButton>
                                    </Tooltip> */}
                                    {markingState.isStart ? (
                                        <Grid container direction="column" className="marking-stuff">
                                            <Grid item>
                                                <ToggleButtonGroup
                                                    fullWidth
                                                    className="marking-toolbar"
                                                    color="secondary"
                                                    value={markingState.markingType}
                                                    exclusive
                                                    size="small"
                                                    onChange={markingTypeSelection}
                                                >
                                                    {props.canUseNewStamp && (
                                                        <ToggleButton value={MarkingType.FreeDrawing}>
                                                            <FiberManualRecord
                                                                fontSize="small"
                                                                style={{
                                                                    color:
                                                                        markingState.markingType === MarkingType.FreeDrawing
                                                                            ? window.fabricCanvas.freeDrawingBrush.color
                                                                            : 'lightgrey'
                                                                }}
                                                            />
                                                            &nbsp;|
                                                            <Icon onClick={handleClick}>
                                                                <ArrowDropDown />
                                                            </Icon>
                                                        </ToggleButton>
                                                    )}
                                                    <ToggleButton value={MarkingType.Text}>
                                                        <TextFields fontSize="small" />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Sign}>
                                                        <BorderColor fontSize="small" />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.DisplayName}>
                                                        <Person fontSize="small" />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Calendar}>
                                                        <CalendarToday fontSize="small" />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Stamp}>
                                                        <Explicit />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Check}>
                                                        <img alt="Check" src={FixedImage.Check} />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Cross}>
                                                        <img alt="Cross" src={FixedImage.Cross} />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.NA}>
                                                        <img alt="NA" src={FixedImage.NA} />
                                                    </ToggleButton>
                                                </ToggleButtonGroup>

                                                <Menu
                                                    dense
                                                    anchorEl={anchorEl}
                                                    open={!!anchorEl}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }}
                                                    onClose={handleClose}
                                                >
                                                    {['red', 'green', 'blue', 'black'].map((clr) => (
                                                        <MenuItem onClick={setColor(clr)}>
                                                            <FiberManualRecord fontSize="small" style={{ color: clr }} />
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            </Grid>
                                            <Grid item>
                                                <ButtonGroup fullWidth className="marking-action" size="small">
                                                    {props.canUseNewStamp && (
                                                        <PrimaryButton
                                                            variant="contained"
                                                            disabled={!markingState.haveAnySelected}
                                                            onClick={deleteActiveFabricObjects}
                                                        >
                                                            Delete
                                                        </PrimaryButton>
                                                    )}
                                                    <PrimaryButton variant="contained" disabled={!haveSomethingToSave} onClick={saveAndRefresh}>
                                                        Save
                                                    </PrimaryButton>
                                                    <SecondaryButton variant="contained" onClick={checkAndStopMarkingMode}>
                                                        Close
                                                    </SecondaryButton>
                                                </ButtonGroup>
                                                {showConfirmation && (
                                                    <HavePendingMarking
                                                        showConfirmation={showConfirmation}
                                                        removeAndClose={props.canUseNewStamp ? clearCanvasAndStopMarkingMode : stopMarkingMode}
                                                        handleClose={() => setShowConfirmation(false)}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <>
                                            <Tooltip title="History of the Document">
                                                <IconButton component="span" onClick={showOcrDocHistoryModal}>
                                                    <Badge
                                                        className="red-on-white"
                                                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                                        badgeContent={state.selectedOcrDocHistoryCount}
                                                        color="primary"
                                                    >
                                                        <History />
                                                    </Badge>
                                                </IconButton>
                                            </Tooltip>
                                            {!isAuditor && (
                                                <Tooltip title="Stamps and Marking">
                                                    <IconButton
                                                        component="span"
                                                        onClick={startMarkingMode}
                                                        color="default"
                                                        className={`${markingState.isStart ? 'stamp-start' : ''}`}
                                                    >
                                                        <AlternateEmailOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        ) : (
                            <div className="document-action">
                                <Tooltip title="History of the Document">
                                    <IconButton component="span" onClick={showOcrDocHistoryModal}>
                                        <Badge
                                            className="red-on-white"
                                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                            badgeContent={state.selectedOcrDocHistoryCount}
                                            color="primary"
                                        >
                                            <History />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                        <div
                            className={`document-viewer custom-scroll${state.isSelectedFilePdf && state.imagesFromUrl.length ? ' multi-image-from-pdf' : ''}${
                                markingState.markingType ? ' marking-start' : ''
                            }`}
                            style={{ height: `calc(100vh - ${getScreenOffset(false)})` }}
                            ref={docContainer}
                        >
                            <div ref={stampContainer} className="refStampContainer">
                                {state.loadingDoc ? (
                                    <LinearProgress />
                                ) : (
                                    <DocStamps currentPageNo={currentPageNo} docContainer={stampContainer} canUseNewStamp={props.canUseNewStamp} />
                                )}
                                {displayDoc()}
                                {props.canUseNewStamp && (
                                    <div className="canvas-wrapper" style={{ display: `${markingState.isStart ? 'block' : 'none'}` }}>
                                        <canvas ref={canvasRef} id="main-canvas" className="main-canvas" />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="actionButton-Container" style={{ marginTop: 5 }}>
                            <Grid container spacing={1}>
                                {state.docHistoryLoading ? (
                                    <>Loading...</>
                                ) : (
                                    state.selectedDocHistory.map((m) => {
                                        return (
                                            <Grid item>
                                                <SecondaryButton
                                                    className="historydoclist"
                                                    title={moment(m.createdAt).format('LLL')}
                                                    variant={m.docName == state.selectedOcrDocName ? 'contained' : 'outlined'}
                                                    onClick={() => downloadDocument(state.selectedOcrDocId, m.docName, false, false, m.isVideo)}
                                                >
                                                    <Image />
                                                </SecondaryButton>
                                            </Grid>
                                        );
                                    })
                                )}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>

                {screenState.showBarCode && (
                    <DialogComp title={`Bar Code - ${workflowData.jobBasicDetail.wipNumber}`} onClose={onBarCodeClose} fullWidth maxWidth="sm">
                        <ShowBarCode wipNo={workflowData.jobBasicDetail.wipNumber} />
                    </DialogComp>
                )}
                {screenState.showJobAssignedUser && (
                    <DialogComp
                        title={`Assigned User - ${workflowData.jobBasicDetail.wipNumber}`}
                        onClose={onJobAssignedUserClose}
                        fullWidth
                        maxWidth="md"
                        overflow="auto"
                    >
                        <JobAssignedUser wipNo={workflowData.jobBasicDetail.wipNumber} jobId={state.jobId} onClose={onJobAssignedUserClose} />
                    </DialogComp>
                )}
                {screenState.showNotesModal && <JobNotesModal jobId={state.jobId} onClose={hideNotesModal} onNotesAdded={notesAdded} />}
                {screenState.showHistoryModal && <WorkflowMovementModal jobId={state.jobId} onClose={hideHistoryModal} />}
                {screenState.showReturnReasonHistoryModal && <ReturnReasonHistoryModal jobId={state.jobId} onClose={hideReturnReasonHistory} />}
                {screenState.showReturnReasonModal && (
                    <ReturnReasonWorkflow
                        canOverrideMandatorySteps={canOverrideMandatorySteps}
                        allFilled={allFilled}
                        workflowId={isArchivedJob ? 7 : state.selectedWorkflowId}
                        jobId={state.jobId}
                        onClose={hideReturnReason}
                        showToast={showToast}
                        ParamProcessId={ParamProcessId}
                    />
                )}
                {screenState.showOcrDocHistory && (
                    <OcrDocHistory
                        jobId={state.jobId}
                        ocrDocId={state.selectedOcrDocId}
                        showDoc={downloadDocument}
                        onClose={hideOcrDocHistoryModal}
                        showToast={showToast}
                        updateState={updateState}
                        ocrDocManage={state.ocrDocManage}
                        selectedDoc={state.selectedOcrDocName}
                    />
                )}
                {screenState.showMailModal && (
                    <MailDocModal
                        jobId={state.jobId}
                        onClose={hideMail}
                        docInfoToMail={docInfoToMail}
                        jobBasicDetail={workflowData.jobBasicDetail}
                        showToast={showToast}
                    />
                )}
                {screenState.showDownloadModal && (
                    <DownloadDocModal
                        jobId={state.jobId}
                        onClose={hideDownload}
                        docInfoToMail={docInfoToMail}
                        jobBasicDetail={workflowData.jobBasicDetail}
                        showToast={showToast}
                    />
                )}
                {screenState.showHeaderDetails && (
                    <DialogComp title="Job Details" maxWidth="md" onClose={() => closeHeaderDetails(false)} fullWidth>
                        <HeaderDetailsModal jobBasicDetail={workflowData.jobBasicDetail} handleCustomerNotes={handleCustomerNotes} />
                    </DialogComp>
                )}
                {screenState.showAllDocModal && (
                    <AllDocModal
                        allDoc={workflowData.workflowDocs}
                        showDoc={showAlldocState}
                        onClose={hideAllDoc}
                        HandelMailDocument={HandelMailDocument}
                        DownloadOcrDocument={DownloadOcrDocument}
                        currentVisibleDocName={state.currentVisibleDocName}
                        isLoadingDoc={state.loadingDoc}
                    />
                )}
                {screenState.showWarning && <WarningMsg onClose={hideWarningMsg} />}
                {screenState.showCustomerNotes && (
                    <DialogComp title="Customer Notes" maxWidth="md" onClose={() => handleCustomerNotes(false)} fullWidth>
                        <CustomerNotes
                            customerName={workflowData.jobBasicDetail.customerName}
                            customerNote={workflowData.jobBasicDetail?.customerInternalNotes}
                        />
                    </DialogComp>
                )}
                {screenState.siteVehicle ? (
                    // <DialogComp title={addActionLogHeaderComp('Vehicle Status')} onClose={() => handleVehicleStatusClose(false)} fullWidth maxWidth="lg">
                    <AddEditSiteVehicle
                        onClose={handleVehicleStatusClose}
                        headerRecordID={workflowData?.jobBasicDetail?.jobId}
                        reg={workflowData?.jobBasicDetail?.regNo}
                        wip={workflowData?.jobBasicDetail?.wipNumber}
                        isShowHistory={true}
                    />
                ) : // </DialogComp>
                null}
                {screenState.history ? (
                    <LogHistory
                        headerRecordID={workflowData?.jobBasicDetail?.jobId}
                        reg={workflowData?.jobBasicDetail?.regNo}
                        wip={workflowData?.jobBasicDetail?.wipNumber}
                        onClose={() => closeHistoryModal(false)}
                        hideAddActionBtn={true}
                    />
                ) : null}
            </div>
        </div>
    );
};

const Screen = (props) => {
    const {
        portalSettings: { canUseNewStamp }
    } = useContext(AppContext);

    return (
        <StateProvider {...props} canUseNewStamp={canUseNewStamp}>
            <WorkflowJobDetails {...props} canUseNewStamp={canUseNewStamp} />
        </StateProvider>
    );
};

export default Screen;
