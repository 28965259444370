import React, { useContext, useMemo, useEffect, useState, useRef } from 'react';
import AppContext from '../../../App/AppContext';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import SignaturePad from 'react-signature-canvas';
import { TextBox, SecondaryButton, DialogContents, AppButtonGroup } from '../../../Core/FormInput';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import '../../technician/technician-app/TechnicianStyle.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import formatters from '../../../Core/Grid/inputFormatter';
import { Backup, Mail, Visibility } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { getBtteryTestDetails, postBtteryTestDetails } from '../../../Core/Service/batteryTest-service';
import { AppStorage } from '../../../Core/Service/storage-service';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormHelperText, InputLabel } from '@material-ui/core';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';

const SecondaryBatteryModal = ({ state, fieldChange, handel, styles }) => {
    return (
        <>
            <Grid item container xs={12} md={12} lg={5} xl={5} alignItems="center">
                <Grid item xs={12} sm={4} md={3} lg={12} xl={10}>
                    <FormLabel className="mandatoryfields">Battery Manufacturer</FormLabel>
                </Grid>
                <Grid container item xs={12} sm={8} md={9} lg={12} xl={11}>
                    <AppButtonGroup
                        name="batteryTestManufacturerID"
                        value={state.list[1]?.batteryTestManufacturerID}
                        onChange={handel(2)}
                        options={state.batteryManufacturers}
                        // disabled={!step.isEditable}
                        valuePropertyName="id"
                        textPropertyName="name"
                    />
                    <span style={styles.required}>{state.secondaryErrors.batteryTestManufacturerID}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <FormLabel component="legend">Barcode (Mode Designation)</FormLabel>
                <TextBox type="text" required name="batteryTestBarcode" value={state.list[1]?.batteryTestBarcode} onChange={fieldChange(2)} />
                <span style={styles.required}>{state.secondaryErrors.batteryTestBarcode}</span>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <FormLabel component="legend">Capacity</FormLabel>
                <TextBox
                    type="number"
                    required
                    name="batteryTestCapacity"
                    value={state.list[1]?.batteryTestCapacity}
                    onChange={fieldChange(2)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">Ah</InputAdornment>
                    }}
                />
                <span style={styles.required}>{state.secondaryErrors.batteryTestCapacity}</span>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <FormLabel component="legend">Cold Test Current</FormLabel>
                <TextBox type="number" required name="batteryTestColdTestCurrent" value={state.list[1]?.batteryTestColdTestCurrent} onChange={fieldChange(2)} />
                <span style={styles.required}>{state.secondaryErrors.batteryTestColdTestCurrent}</span>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4}>
                <FormLabel component="legend">MB Part Number</FormLabel>
                <TextBox type="text" required name="batteryTestMBPartNumber" value={state.list[1]?.batteryTestMBPartNumber} onChange={fieldChange(2)} />
                <span style={styles.required}>{state.secondaryErrors.batteryTestMBPartNumber}</span>
            </Grid>
            <Grid container item xs={12} sm={6} md={6} lg={4} xl={4}>
                <Grid item xs={12}>
                    <FormLabel component="legend" className="mandatoryfields">
                        Production Date (on negative terminal)
                    </FormLabel>
                </Grid>
                <Grid container item xs={6} alignItems="center" justify="flex-start">
                    {/* <FormLabel component="legend" className="mandatoryfields">
                                                    Weeks 
                                                </FormLabel> */}

                    <TextBox
                        type="tel"
                        placeholder="Week"
                        required
                        name="batteryTestProductionWeek"
                        value={state.list[1]?.batteryTestProductionWeek}
                        onChange={fieldChange(2)}
                        inputProps={{ maxLength: 2 }}
                    />
                </Grid>
                <Grid container item xs={6} alignItems="center" style={{ paddingLeft: 10 }}>
                    <TextBox
                        type="tel"
                        placeholder="Year"
                        required
                        name="batteryTestProductionYear"
                        value={state.list[1]?.batteryTestProductionYear}
                        onChange={fieldChange(2)}
                        inputProps={{ maxLength: 4 }}
                    />
                </Grid>
                <span style={styles.required}>{state.secondaryErrors.batteryTestProductionWeek}</span>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
                <div style={{ padding: '0px 5px' }}>
                    <Typography style={{ float: 'left', marginBottom: '-5px' }} variant="h6">
                        Visual Inspection
                    </Typography>
                    <Grid container>
                        <Grid container item xs={12} sm={6} md={6} spacing={1} alignItems="center">
                            <Grid item>
                                <FormLabel component="legend" className="mandatoryfields">
                                    External signs of leaks
                                </FormLabel>
                            </Grid>
                            <Grid item>
                                <AppButtonGroup
                                    name="batteryTestLeak"
                                    value={state.list[1]?.batteryTestLeak}
                                    onChange={handel(2)}
                                    options={state.yesNoOptions}
                                    valuePropertyName="id"
                                    textPropertyName="name"
                                />
                            </Grid>
                            <span style={styles.required}>{state.secondaryErrors.batteryTestLeak}</span>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormLabel component="legend" className={`${state.batteryTestLeak === 'true' ? 'mandatoryfields' : ''}`}>
                                If yes, where is the leak:
                            </FormLabel>
                            <TextBox
                                type="text"
                                required
                                name="batteryTestLeakPosition"
                                value={state.list[1]?.batteryTestLeakPosition}
                                onChange={fieldChange(2)}
                            />
                            <span style={styles.required}>{state.secondaryErrors.batteryTestLeakPosition}</span>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
                <div div style={{ padding: '0px 5px' }}>
                    <Typography style={{ float: 'left', marginBottom: '-5px' }} variant="h6">
                        Midronics Test
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid container item alignItems="center" spacing={1}>
                            <Grid item>
                                <FormLabel component="legend" className="mandatoryfields">
                                    Result
                                </FormLabel>
                            </Grid>

                            <Grid item>
                                <AppButtonGroup
                                    name="batteryTestMidtronicsTestResult"
                                    value={state.list[1]?.batteryTestMidtronicsTestResult}
                                    onChange={handel(2)}
                                    options={state.batteryTestResults}
                                    // disabled={!step.isEditable}
                                    valuePropertyName="batteryTestResultCode"
                                    textPropertyName="batteryTestResultName"
                                />
                            </Grid>
                            <span style={styles.required}>{state.secondaryErrors.batteryTestMidtronicsTestResult}</span>
                            {/* <Grid item>
                                                        <PrimaryButton
                                                            className="setFullWidth"
                                                            onClick={() =>
                                                                setState((st) => ({
                                                                    ...st,
                                                                    open: true
                                                                }))
                                                            }
                                                        >
                                                            Secondary Battery
                                                        </PrimaryButton>
                                                    </Grid> */}
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <Grid item xs={12}>
                <div style={{ padding: '0px 5px' }}>
                    {/* <Typography style={{ float: 'left', marginBottom: '10px' }} variant="h6">
                                                Midronics Test
                                            </Typography> */}
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <FormLabel component="legend">Test Code</FormLabel>
                            <TextBox type="text" required name="batteryTestTestCode" value={state.list[1]?.batteryTestTestCode} onChange={fieldChange(2)} />
                            <span style={styles.required}>{state.secondaryErrors.batteryTestTestCode}</span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormLabel component="legend">Damage Code</FormLabel>
                            <TextBox type="text" required name="batteryTestDamageCode" value={state.list[1]?.batteryTestDamageCode} onChange={fieldChange(2)} />
                            <span style={styles.required}>{state.secondaryErrors.batteryTestDamageCode}</span>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </>
    );
};

export default SecondaryBatteryModal;
