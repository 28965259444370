import { Grid, FormLabel, Typography, InputLabel, FormHelperText, FormControlLabel, Checkbox } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../App/AppContext';
import { DatePicker, SecondaryButton, SingelSelect, TextBox } from '../../../Core/FormInput';
import { getBranchCodes, getReportingTypeList, setBranchCodes } from '../../../Core/Service/branch-screen-service';
import TabComponent from '../../../Core/Controls/Tabs';

import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import moment from 'moment';

// const ReportingTypeList = [
//     { id: 'C', name: 'Cash' },
//     { id: 'CO', name: 'Consumables' },
//     { id: 'MV', name: 'MobiloVan' },
//     { id: 'RM', name: 'RandM' },
//     { id: 'W', name: 'Warranty' },
//     { id: 'R', name: 'Rectification' },
//     { id: 'SW', name: 'Service Warranty Write Off' },
//     { id: 'PW', name: 'Parts Warranty Write Off' }
// ];

const UpdateBranchCodesModal = (props) => {
    const [state, setState] = useState({
        BranchInvoiceEmail: '',
        BranchContractBranchName: '',
        BranchRandMCodes: '',
        BranchWarrantyCodes: '',
        BranchCashCodes: '',
        BranchRectificationAccountCode: '',
        BranchServiceWarrantyWriteOffAccountCode: '',
        BranchPartsWarrantyWriteOffAccountCode: '',
        BranchConsumablesAccountCode: '',
        BranchMobiloVanAccountCode: '',
        BranchExcludeLabourCode: '',
        ReportingTypeList: [],
        branchIncludeInEWIP: false,
        branchIncludeInReporting: false,
        branchIncludeInInvoicePortal: false,
        branchSeteWIPWorkflowID: false,
        excludeBranch: false,
        branchWorkflowChangeNotificationEmail: '',
        branchReturnReasonNotificationEmail: ''
    });

    const [reportingVM, setReportingVM] = useState([{ codeID: null, codeType: {}, code: '', isDeleted: 0 }]);

    const { showToast } = useContext(AppContext);

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = checked;
            return nst;
        });
    };

    const handleSubmit = async () => {
        const data = {
            BranchID: props.branchID,
            BranchInvoiceEmail: state.BranchInvoiceEmail ? state.BranchInvoiceEmail : '',
            BranchContractBranchName: state.BranchContractBranchName ? state.BranchContractBranchName : '',
            BranchShortCode: state.branchShortCode || '',
            BranchShortName: state.branchShortName || '',
            BranchName: state.branchName || '',
            BranchIncludeInEWIP: state.branchIncludeInEWIP,
            BranchIncludeInReporting: state.branchIncludeInReporting,
            BranchIncludeInInvoicePortal: state.branchIncludeInInvoicePortal,
            BranchSeteWIPWorkflowID: state.branchSeteWIPWorkflowID,
            BranchGoLiveDate: state.branchGoLiveDate,
            ExcludeBranch: state.excludeBranch,
            branchWorkflowChangeNotificationEmail: state.branchWorkflowChangeNotificationEmail,
            branchReturnReasonNotificationEmail: state.branchReturnReasonNotificationEmail,
            // BranchRandMCodes: state.BranchRandMCodes ? state.BranchRandMCodes : '',
            // BranchWarrantyCodes: state.BranchWarrantyCodes ? state.BranchWarrantyCodes : '',
            // BranchCashCodes: state.BranchCashCodes ? state.BranchCashCodes : '',
            // BranchRectificationAccountCode: state.BranchRectificationAccountCode ? state.BranchRectificationAccountCode : '',
            // BranchServiceWarrantyWriteOffAccountCode: state.BranchServiceWarrantyWriteOffAccountCode ? state.BranchServiceWarrantyWriteOffAccountCode : '',
            // BranchPartsWarrantyWriteOffAccountCode: state.BranchPartsWarrantyWriteOffAccountCode ? state.BranchPartsWarrantyWriteOffAccountCode : '',
            // BranchConsumablesAccountCode: state.BranchConsumablesAccountCode ? state.BranchConsumablesAccountCode : '',
            // BranchMobiloVanAccountCode: state.BranchMobiloVanAccountCode ? state.BranchMobiloVanAccountCode : '',
            BranchExcludeLabourCode: state.BranchExcludeLabourCode ? state.BranchExcludeLabourCode : '',
            ReportingCodes: reportingVM.map((k) => {
                return {
                    ...k,
                    codeID: k.codeID ? k.codeID : null,
                    code: k.code ? k.code : '',
                    codeType: k.codeType.id
                };
            })
        };
        let res = await setBranchCodes(data);
        if (!res.success) {
            console.error(res);
            showToast('Error occured.');
        } else {
            showToast('Branch Codes updated successfully');
            props.onClose();
        }
    };

    useEffect(async () => {
        let res = await getReportingTypeList();
        if (res.success) {
            setState((st) => ({
                ...st,
                ReportingTypeList: res.data.reportingCode
            }));
        }
    }, []);

    const pullAndUpdateState = async () => {
        let res = await getBranchCodes(props.branchID);
        if (res.success) {
            const data = res.data.dt[0];
            console.log(res.data, 'res.data');
            const ReportData = res.data.reportingCodes;
            setState((st) => ({
                ...st,
                BranchInvoiceEmail: data.branchInvoiceEmail,
                BranchContractBranchName: data.branchContractBranchName,
                branchShortCode: data.branchShortCode,
                branchShortName: data.branchShortName,
                branchName: data.branchName,
                branchIncludeInEWIP: data.branchIncludeInEWIP,
                branchIncludeInReporting: data.branchIncludeInReporting,
                branchIncludeInInvoicePortal: data.branchIncludeInInvoicePortal,
                branchSeteWIPWorkflowID: data.branchSeteWIPWorkflowID,
                branchGoLiveDate: moment(data.branchGoLiveDate).format('YYYY-MM-DD'), // data.branchGoLiveDate,
                excludeBranch: data.excludeBranch,
                branchWorkflowChangeNotificationEmail: data.branchWorkflowChangeNotificationEmail,
                branchReturnReasonNotificationEmail: data.branchReturnReasonNotificationEmail,
                // BranchRandMCodes: data.branchRandMCodes,
                // BranchWarrantyCodes: data.branchWarrantyCodes,
                // BranchCashCodes: data.branchCashCodes,
                // BranchRectificationAccountCode: data.branchRectificationAccountCode,
                // BranchServiceWarrantyWriteOffAccountCode: data.branchServiceWarrantyWriteOffAccountCode,
                // BranchPartsWarrantyWriteOffAccountCode: data.branchPartsWarrantyWriteOffAccountCode,
                // BranchConsumablesAccountCode: data.branchConsumablesAccountCode,
                // BranchMobiloVanAccountCode: data.branchMobiloVanAccountCode,
                BranchExcludeLabourCode: data.branchExcludeLabourCode
            }));
            setReportingVM(
                !ReportData?.length
                    ? [
                          {
                              codeID: null,
                              codeType: {},
                              code: '',
                              isDeleted: 0
                          }
                      ]
                    : ReportData.map((k) => {
                          return {
                              ...k,
                              codeID: k.codeID ? k.codeID : null,
                              code: k.code ? k.code : '',
                              codeType: state.ReportingTypeList.find((n) => n.id === k.codeType),
                              isDeleted: 0
                          };
                      })
            );
        }
    };

    useEffect(() => {
        if (state.ReportingTypeList.length > 0) {
            pullAndUpdateState();
        }
    }, [state.ReportingTypeList]);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const addTimeRow = () => {
        setReportingVM((st) => [
            ...st,
            {
                codeID: null,
                codeType: {},
                code: '',
                isDeleted: 0
            }
        ]);
    };

    const removeTimeRow = (indx) => {
        setReportingVM((st) => {
            const newSt = [...st];
            newSt.splice(indx, 1);
            return newSt;
        });
    };

    const singleSelectChange = (index) => (ev, val) => {
        setReportingVM((st) => {
            let newSt = [...st];
            let findIndex = [...st].find((n, i) => i === index);
            if (findIndex) {
                findIndex.codeType = val;
            }

            return newSt;
        });
    };

    const handlepart = (index) => (e) => {
        let { name, value } = e.target;
        setReportingVM((st) => {
            let newSt = [...st];
            let findIndex = [...st].find((n, i) => i === index);
            if (findIndex) {
                findIndex[name] = value;
            }

            return newSt;
        });
    };
    const handleDel = (index) => () => {
        setReportingVM((st) => {
            let newSt = [...st];
            let findIndex = [...st].find((n, i) => i === index);
            if (findIndex) {
                findIndex.isDeleted = 1;
            }

            return newSt;
        });
    };

    console.log(state, 'excludeBranch');

    const tabs = [
        {
            label: 'Detail',
            body: (
                <div style={{ padding: 10 }}>
                    {' '}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Invoice Email</InputLabel>
                            <TextBox value={state.BranchInvoiceEmail} name="BranchInvoiceEmail" onChange={handleFieldChange} inputProps={{ maxLength: 256 }} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Contract Branch Name</InputLabel>
                            <TextBox
                                value={state.BranchContractBranchName}
                                name="BranchContractBranchName"
                                onChange={handleFieldChange}
                                inputProps={{ maxLength: 128 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Workflow Email</InputLabel>
                            <TextBox
                                value={state.branchWorkflowChangeNotificationEmail}
                                name="branchWorkflowChangeNotificationEmail"
                                onChange={handleFieldChange}
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Return Reason Email</InputLabel>
                            <TextBox
                                value={state.branchReturnReasonNotificationEmail}
                                name="branchReturnReasonNotificationEmail"
                                onChange={handleFieldChange}
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>Invoiced Hours exclude labour code</InputLabel>
                            <TextBox
                                value={state.BranchExcludeLabourCode}
                                name="BranchExcludeLabourCode"
                                onChange={handleFieldChange}
                                inputProps={{ maxLength: 1 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>Branch Short Code</InputLabel>
                            <TextBox value={state.branchShortCode} name="branchShortCode" onChange={handleFieldChange} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>Branch Name</InputLabel>
                            <TextBox value={state.branchName} name="branchName" onChange={handleFieldChange} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>Branch Short Name</InputLabel>
                            <TextBox value={state.branchShortName} name="branchShortName" onChange={handleFieldChange} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>Branch Go Live Date</InputLabel>
                            <DatePicker
                                style={{ marginTop: 8 }}
                                placeholder="Start Date"
                                name="branchGoLiveDate"
                                fullWidth
                                value={state.branchGoLiveDate}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}></Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={state.branchIncludeInEWIP} onChange={handleCheckbox} name="branchIncludeInEWIP" />}
                                label="Include In EWIP?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={state.branchIncludeInInvoicePortal} onChange={handleCheckbox} name="branchIncludeInInvoicePortal" />
                                }
                                label="Include In Invoice Portal?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={state.branchIncludeInReporting} onChange={handleCheckbox} name="branchIncludeInReporting" />}
                                label="Include In Reporting?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={state.branchSeteWIPWorkflowID} onChange={handleCheckbox} name="branchSeteWIPWorkflowID" />}
                                label="Set eWIP WorkflowID?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={state.excludeBranch} onChange={handleCheckbox} name="excludeBranch" />}
                                label="Exclude Branch?"
                            />
                        </Grid>
                    </Grid>
                </div>
            )
        },
        {
            label: 'Reporting',
            body: (
                <div style={{ padding: 10 }}>
                    <Grid container spacing={1} justifyContent="space-evenly">
                        {reportingVM.map((row, index) => {
                            return (
                                !row.isDeleted && (
                                    <React.Fragment key={index}>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <InputLabel shrink>Reporting Type</InputLabel>

                                            <SingelSelect options={state.ReportingTypeList} value={row.codeType} onChange={singleSelectChange(index)} />
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={7} lg={7}>
                                            <InputLabel shrink>Code</InputLabel>
                                            <TextBox type="text" placeholder="Code" name="code" value={row.code} onChange={handlepart(index)} />
                                        </Grid>
                                        <Grid item xs={12} sm={1} md={1} lg={1}>
                                            <div style={{ float: 'right', marginTop: 35 }}>
                                                {index !== 0 && (
                                                    <RemoveCircleIcon
                                                        style={{ color: 'red', cursor: 'pointer' }}
                                                        fontSize="medium"
                                                        // onClick={() => {
                                                        //     removeTimeRow(index);
                                                        // }}
                                                        onClick={handleDel(index)}
                                                    />
                                                )}
                                                &nbsp;&nbsp;
                                                {index === 0 && (
                                                    <AddBoxIcon style={{ color: 'green', cursor: 'pointer' }} fontSize="medium" onClick={addTimeRow} />
                                                )}
                                            </div>
                                        </Grid>
                                    </React.Fragment>
                                )
                            );
                        })}
                    </Grid>
                </div>
            )
        }
    ];

    return (
        <div>
            <Grid item style={{ height: '600px', width: '100%', overflow: 'auto' }}>
                <TabComponent tabList={tabs} />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
                <SecondaryButton fullWidth onClick={handleSubmit}>
                    Save
                </SecondaryButton>
            </Grid>
            {/* <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormLabel component="legend">Invoice Email</FormLabel>
                    <TextBox value={state.BranchInvoiceEmail} name="BranchInvoiceEmail" onChange={handleFieldChange} inputProps={{ maxLength: 256 }} />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel component="legend">Contract Branch Name</FormLabel>
                    <TextBox
                        value={state.BranchContractBranchName}
                        name="BranchContractBranchName"
                        onChange={handleFieldChange}
                        inputProps={{ maxLength: 128 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel component="legend">RandM Codes</FormLabel>
                    <TextBox value={state.BranchRandMCodes} name="BranchRandMCodes" onChange={handleFieldChange} inputProps={{ maxLength: 128 }} />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel component="legend">Warranty Codes</FormLabel>
                    <TextBox value={state.BranchWarrantyCodes} name="BranchWarrantyCodes" onChange={handleFieldChange} inputProps={{ maxLength: 128 }} />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel component="legend">Cash Codes</FormLabel>
                    <TextBox value={state.BranchCashCodes} name="BranchCashCodes" onChange={handleFieldChange} inputProps={{ maxLength: 128 }} />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel component="legend">Rectification Account Code</FormLabel>
                    <TextBox
                        value={state.BranchRectificationAccountCode}
                        name="BranchRectificationAccountCode"
                        onChange={handleFieldChange}
                        inputProps={{ maxLength: 12 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel component="legend">Service Warranty Write Off Account Code</FormLabel>
                    <TextBox
                        value={state.BranchServiceWarrantyWriteOffAccountCode}
                        name="BranchServiceWarrantyWriteOffAccountCode"
                        onChange={handleFieldChange}
                        inputProps={{ maxLength: 12 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel component="legend">Parts Warranty Write Off Account Code</FormLabel>
                    <TextBox
                        value={state.BranchPartsWarrantyWriteOffAccountCode}
                        name="BranchPartsWarrantyWriteOffAccountCode"
                        onChange={handleFieldChange}
                        inputProps={{ maxLength: 12 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel component="legend">Consumables Account Code</FormLabel>
                    <TextBox
                        value={state.BranchConsumablesAccountCode}
                        name="BranchConsumablesAccountCode"
                        onChange={handleFieldChange}
                        inputProps={{ maxLength: 12 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel component="legend">Mobilo Van Account Code</FormLabel>
                    <TextBox
                        value={state.BranchMobiloVanAccountCode}
                        name="BranchMobiloVanAccountCode"
                        onChange={handleFieldChange}
                        inputProps={{ maxLength: 12 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel component="legend">Invoiced Hours exclude labour code</FormLabel>
                    <TextBox value={state.BranchExcludeLabourCode} name="BranchExcludeLabourCode" onChange={handleFieldChange} inputProps={{ maxLength: 1 }} />
                </Grid>

                <Grid item xs={12}>
                    <Typography style={{ color: 'red' }} variant="p">
                        Note: Codes can be comma seperated i.e (1233,5232)
                    </Typography>
                </Grid>
                
            </Grid> */}
        </div>
    );
};
export default UpdateBranchCodesModal;
