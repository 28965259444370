import { get, post, put } from './http-calls';
import { memoize } from 'lodash';
import moment from 'moment';

export const getWorkflows = async (IsAllRecord = 'N') => {
    let res = await get(`workflow?IsAllRecord=${IsAllRecord ? `${IsAllRecord}` : ''}`, { useAuthToken: true });
    return res;
};

export const getWorkflowsByProcessID = async (ProcessID, IsAllRecord = 'N') => {
    let res = await get(`workflow/ByProcessID/${ProcessID}?IsAllRecord=${IsAllRecord ? `${IsAllRecord}` : ''}`, { useAuthToken: true });
    return res;
};

export const getAllowedWorkflow = async () => {
    let res = await get(`WorkflowJobs/GetAllowedWorkflows`, { useAuthToken: true });
    return res;
};

export const getWorkflowSteps = async (workflowId, jobId, IsAllRecord = 'N') => {
    let res = await get(`workflow/${workflowId}${jobId ? `/${jobId}` : ''}?IsAllRecord=${IsAllRecord ? `${IsAllRecord}` : ''}`, { useAuthToken: true });
    return res;
};

export const addWorkflow = async (
    name,
    nextWorkFlow,
    orderIndex,
    IsForTechnician,
    IsEntryPoint,
    IsEndPoint,
    timesIntervalDuration,
    timedInterval,
    timedIntervalTypeAfter,
    timedWorkflowId,
    displayJobPriorityButton,
    displayCustomerRatingButton,
    workflowProcessID,
    displayWebFormType,
    webFormID,
    technicianAllowed,
    internalAllowed,
    customerAllowed,
    auditorAllowed,
    workflowNotificationList
) => {
    let res = await post(
        `workflow`,
        {
            workflowName: name,
            IsEntryPoint: IsEntryPoint,
            NextWorkflowId: nextWorkFlow,
            WorkflowForTechnician: IsForTechnician,
            OrderIndex: orderIndex,
            IsEndPoint: IsEndPoint,
            timesIntervalDuration: timesIntervalDuration,
            timedInterval: timedInterval,
            timedIntervalTypeAfter: timedIntervalTypeAfter,
            timedWorkflowId: timedWorkflowId,
            displayJobPriorityButton: displayJobPriorityButton,
            displayCustomerRatingButton: displayCustomerRatingButton,
            workflowProcessID: workflowProcessID,
            displayWebFormType: displayWebFormType,
            webFormID: webFormID,
            technicianAllowed,
            internalAllowed,
            customerAllowed,
            auditorAllowed,
            workflowNotificationList: workflowNotificationList
        },
        { useAuthToken: true }
    );
    return res;
};

export const updateWorkflow = async (
    workflowId,
    name,
    IsDeleted,
    nextWorkFlow,
    orderIndex,
    IsForTechnician,
    IsEntryPoint,
    IsEndPoint,
    timesIntervalDuration,
    timedInterval,
    timedIntervalTypeAfter,
    timedWorkflowId,
    displayJobPriorityButton,
    displayCustomerRatingButton,
    workflowProcessID,
    displayWebFormType,
    webFormID,
    technicianAllowed,
    internalAllowed,
    customerAllowed,
    auditorAllowed,
    workflowNotificationList
) => {
    let res = await put(
        `workflow/${workflowId}`,
        {
            workflowName: name,
            IsDeleted: IsDeleted ? false : true,
            IsEntryPoint: IsEntryPoint,
            NextWorkflowId: nextWorkFlow,
            WorkflowForTechnician: IsForTechnician,
            OrderIndex: orderIndex,
            IsEndPoint: IsEndPoint,
            timesIntervalDuration: timesIntervalDuration,
            timedInterval: timedInterval,
            timedIntervalTypeAfter: timedIntervalTypeAfter,
            timedWorkflowId: timedWorkflowId,
            displayJobPriorityButton: displayJobPriorityButton,
            displayCustomerRatingButton: displayCustomerRatingButton,
            workflowProcessID: workflowProcessID,
            displayWebFormType: displayWebFormType,
            webFormID: webFormID,
            technicianAllowed,
            internalAllowed,
            customerAllowed,
            auditorAllowed,
            workflowNotificationList: workflowNotificationList
        },
        { useAuthToken: true }
    );
    return res;
};

export const WorkflowGetDetails = async (id) => {
    let res = await get(`Workflow/Workflow_GetNotificationDetails?${id ? `workflowId=${id}` : ''}`, { useAuthToken: true });
    return res;
};

export const deleteWorflow = async (workflowId) => {
    let res = await post(`Workflow/DeleteWorkflow/${workflowId}`, { workflowId: workflowId }, { useAuthToken: true });
    return res;
};

export const getGridFilterList = async () => {
    let res = await get(`UserGridColumn`, { useAuthToken: true });
    return res;
};

export const postGridFilterList = async (data) => {
    let res = await post(`UserGridColumn`, data, { useAuthToken: true });
    return res;
};

export const postSetPriority = async (data) => {
    let res = await post(`workflow/SetPriority`, data, { useAuthToken: true });
    return res;
};

export const postCustomerRating = async (data) => {
    let res = await post(`workflow/SetCustomerRating`, data, { useAuthToken: true });
    return res;
};
export const getCustomerRating = async (ID) => {
    let res = await get(`Workflow/CustomerRating/${ID}`, { useAuthToken: true });
    return res;
};

const downloadFileWithExt = async (res, fileExt) => {
    if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
        link.id = 'tempDownloadPDFLink';
        link.href = url;
        link.setAttribute('download', `Jobs${moment().format('DD-MMM-YYYY HH:mm')}.${fileExt}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return true;
    }
};

export const GetArchivedJobsExcelFile = async (params) => {
    let res = await get(`WorkflowJobs/GetExcelDownload${params}`, {
        responseType: 'blob',
        returnOrgRes: true,
        useAuthToken: true
    });
    downloadFileWithExt(res, 'xlsx');
};

export const getWorkflowNewUsedJobStep = async (workflowId, StockID, IsNew = true) => {
    let res = await get(`workflow/Stock/${workflowId}/${StockID}?IsAllRecord=N&IsNew=${IsNew}`, { useAuthToken: true });
    return res;
};

export const getNewStockData = async (StockID) => {
    let res = await get(`NewStockData/${StockID}`, { useAuthToken: true });
    return res;
};

export const getUsedStockData = async (StockID) => {
    let res = await get(`UsedStockData/${StockID}`, { useAuthToken: true });
    return res;
};
