import { get, post } from './http-calls';
import { memoize } from 'lodash';
import { AppStorage } from './storage-service';

export const getAuthenticatorCode = async () => {
    let res = await get('TwoFactorAuthentication/key', { useAuthToken: true });
    return res;
};

export const postAuthenticationCode = async (code) => {
    let res = await post(`TwoFactorAuthentication/Register2fa/${code}`, {}, { useAuthToken: true });
    return res;
};
export const postAuthenticationDisbaledCode = async (code) => {
    let res = await post(`TwoFactorAuthentication/Disabled2FA/${code}`, {}, { useAuthToken: true });
    return res;
};
export const postAuthenticationValidateCode = async (code) => {
    let res = await post(`/validate2fa/${code}`, {});
    if (res.success) {
        AppStorage.performLogin(res);
    }
    return res;
};
