import React, { useEffect, useState, useContext } from 'react';
import { FormLabel, Grid, makeStyles } from '@material-ui/core';
import { TextBoxShrink } from '../../../../Core/FormInput';
import { get } from 'lodash';
import { CustomeSignPad } from '../../../../Core/SignaturePad';
import AppContext from '../../../../App/AppContext';

const useStyles = makeStyles((theme) => ({
    signFormControl: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 4,
        '& > label': {
            background: 'white',
            padding: '0px 5px'
        },
        '& > canvas': {
            width: '100%'
        }
    },
    clearSign: {
        position: 'absolute',
        bottom: 10,
        right: 10
    }
}));

export const DriverDetails = React.memo((props) => {
    const classes = useStyles();
    // const [hasSign, setHasSign] = useState(true);
    const { state, setState } = props; //useContext(JobSheetContext);
    // let canvasRef;
    const { userRoles } = useContext(AppContext);
    const isAuditor = userRoles.includes('auditor');

    const setField = (e) => {
        const { name, type, value, valueAsNumber, checked } = e.target;
        if (type === 'number') {
            setState((st) => ({ ...st, [name]: valueAsNumber }));
        } else if (type === 'checkbox') {
            setState((st) => ({ ...st, [name]: checked }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };

    const getField = (name) => {
        return get(state, name);
    };

    // const setSign = () => {
    //     const service24DriverSignature = canvasRef.toDataURL();
    //     console.log(service24DriverSignature);
    //     setState((st) => ({ ...st, service24DriverSignature }));
    // };
    const GetTechnicianSign = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };
    const clearSign = () => {
        // canvasRef.clear();
        setState((st) => ({ ...st, service24DriverSignature: undefined }));
    };

    // useEffect(() => {
    //     setHasSign(state.service24DriverSignature);
    // }, [canvasRef, state.service24DriverSignature]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <TextBoxShrink
                            onChange={setField}
                            value={getField('service24DriverName')}
                            label="Name"
                            name="service24DriverName"
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextBoxShrink
                            onChange={setField}
                            value={getField('service24DriverPhone')}
                            label="Tel No"
                            name="service24DriverPhone"
                            inputProps={{ maxLength: 20 }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {!isAuditor && <Grid item xs={12} sm={6} style={{ marginTop: '-32px' }}>
                <CustomeSignPad
                    onChange={GetTechnicianSign}
                    content={
                        <FormLabel component="legend" className="mandatoryfields">
                            Signature
                            {state.service24DriverDateSigned && (
                                <span> ({`On Behalf of customer:${state.driverName} ${state.service24DriverDateSigned}`})</span>
                            )}
                        </FormLabel>
                    }
                    sign={getField('driverSignature')}
                    name="service24DriverSignature"
                    clearSig={clearSign}
                />
            </Grid>}
        </Grid>
    );
});
