import { get, post, put } from "./http-calls";

export const changeUserPassword = async (currentpass, newpassword, confirm) => {
    let res = await post(`/changePassword`, {
        currentPassword: currentpass,
        newPassword: newpassword,
        confirmPassword: confirm,
    }, { useAuthToken: true });
    return res;
};

export const SetUserPassword = async (newpassword, confirm,id,token) => {   
    let res = await post(`/setNewPassword/${id}`, {
        newPassword: newpassword,
        confirmPassword: confirm,
        passwordResetToken: token
    });
    return res;
};
