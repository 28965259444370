import { CircularProgress, FormLabel, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useContext, useState } from 'react';
import { SecondaryButton } from '../../../../Core/FormInput';
import MailDocModal from '../more/MailDoc';
import DynamicFormFields from './dynamicFormFields';
import { DynamicFormFieldProvider as StateProvider, ScreenContext } from './DynamicFormProvider';
import doc from '../doc.png';
import { getDocumentByName } from '../../../../Core/Service/workflowJobDocument-service';
import DialogComp from '../../../../Core/Modal/dialogModal';
import { useCallback } from 'react';
import AppContext from '../../../../App/AppContext';
import { getGetDynamicFormDocuments, deleteDocDynamicFormField, saveDynamicFormFieldUploadDoc } from '../../../../Core/Service/dynamic-forms-service';
import MultiImageSliderModal from './MultiImageSliderModal';
import getMultiImgResized from '../../../../Core/CommonFunctions/multiImgResizer';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';

const RenderForm = (props) => {
    const { headerRecordId, dynamicFormID } = props;
    const { state, dropDownValueSelected, dynamicFormData, tryToSaveDynamicFormData, screenState, hideMail, addDocumentImagesData, updateState } =
        useContext(ScreenContext);
    const [WindowWidths, WindowHeights] = useWindowSize();

    const { allFilled } = screenState;
    const saveBtnEnabled = allFilled;

    const [currentPageNo, setPageNo] = useState(0);
    const { showToast } = useContext(AppContext);

    const downloadDocument = async (jobId, fieldId) => {
        updateState({
            isLoading: true
        });
        let res = await getGetDynamicFormDocuments(jobId, fieldId);
        if (res.success) {
            updateState({
                isLoading: false,
                imageList: res.data || []
            });
        } else {
            updateState({
                isLoading: false
            });
        }
    };

    const uploadFiles = async (e, arrName) => {
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append(e.target.files[i].name, e.target.files[i]);
        }
        updateState({
            isUploading: true
        });
        let res = await saveDynamicFormFieldUploadDoc(headerRecordId, state.selectedFieldData?.fieldId, formData);
        if (res.success) {
            downloadDocument(headerRecordId, state.selectedFieldData?.fieldId);
            showToast('File Uploaded successfully.');
            updateState({
                isUploading: false
            });
        }
    };

    const onImageRemove = async (data) => {
        let res = await deleteDocDynamicFormField(headerRecordId, data.id);
        if (res.success) {
            let tempData = state.imageList;
            let objIndex = tempData.findIndex((x) => x.id === data.id);
            if (objIndex > -1) {
                tempData.splice(objIndex, 1);
            }
            updateState({
                imageList: tempData
            });
            // downloadDocument(headerRecordId, state.selectedFieldData?.fieldId);
            showToast(res.message);
        }
    };

    const pullDocumentWithPage = useCallback(async (docId, docName, pageNo = 1) => {
        return getDocumentByName(docName, pageNo).then((res) => {
            addDocumentImagesData(docId, docName, res.imgBase64Src);
            return res.hasMorePage && pullDocumentWithPage(docId, docName, pageNo + 1);
        });
    }, []);

    const displayDoc = () => {
        if (state.imagesFromUrl.length === 0) {
            return <img src={doc} alt="No document selected" className="sample-doc" />;
        } else {
            return (
                <div style={{ padding: '10px' }}>
                    <img alt="Document's" src={state.imagesFromUrl[currentPageNo]} />
                </div>
            );
        }
    };

    const mainContainerPaddingPercentage = WindowWidths < 500 ? '3%' : WindowWidths < 800 ? '6%' : WindowWidths < 1200 ? '10%' : '20%';

    return (
        <Grid
            item
            xs={12}
            style={{
                paddingLeft: mainContainerPaddingPercentage,
                paddingRight: mainContainerPaddingPercentage,
                paddingTop: '10px',
                paddingBottom: '30px',
                textAlign: 'left'
            }}
        >
            {state.showLoader ? (
                <Grid item sm={12} container alignItems="center" justify="center" style={{ width: 'calc(100vh - 130px)', height: 'calc(100vh - 175px)' }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container spacing={2} style={{ outline: '1px solid #D7E2DC', background: '#fff' }}>
                    <Grid item xs={12}>
                        <Alert severity="info" style={{ width: 'auto', height: 'auto' }}>
                            <Typography style={{ fontWeight: 'bold' }}>{dynamicFormData.dynamicFormDescription}</Typography>
                        </Alert>
                    </Grid>

                    <Grid item xs={12}>
                        <span style={{ color: 'red' }}>*</span> Required
                    </Grid>

                    <Grid xs={12}>
                        <hr style={{ marginLeft: '10px', marginRight: '10px', border: '1px dashed #D7E2DC' }} />
                    </Grid>
                    <Grid item xs={12}>
                        {state.formFields.map((s, i) => {
                            return (
                                <>
                                    <div
                                        style={{
                                            // backgroundColor: i % 2 === 0 ? '#FbFbFb' : '#F5F5F5'
                                            padding: '8px 8px'
                                        }}
                                    >
                                        <DynamicFormFields
                                            key={s.stepId}
                                            fieldLabel={`${1 + i}. ${s.fieldLabel}`}
                                            step={s}
                                            jobId={headerRecordId}
                                            tryToSaveDynamicFormData={tryToSaveDynamicFormData}
                                            // technicianJobId={state.technicianJobId}
                                            dropdownValue={dynamicFormData.tempMergedData[s.jsonPropertyName]}
                                            onDropdownChange={dropDownValueSelected}
                                            downloadDocument={downloadDocument}
                                            currentPageNo={currentPageNo}
                                            // isTechReadonly={state.isTechReadonly}
                                        />
                                    </div>
                                    <Grid item xs={12}>
                                        <hr style={{ margin: '15px 0px', border: '1px dashed #D7E2DC' }} />
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                    <Grid item>
                        <SecondaryButton onClick={() => tryToSaveDynamicFormData(false)} disabled={!saveBtnEnabled}>
                            Save
                        </SecondaryButton>
                    </Grid>
                    <Grid item>
                        <SecondaryButton onClick={props.closeFormModal}>Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            )}

            {state.showDoc && (
                <MultiImageSliderModal
                    imageList={state.imageList}
                    selectedFieldData={state.selectedFieldData}
                    onImageUpload={uploadFiles}
                    isDelete={state.selectedFieldData?.isDeleted}
                    onImageRemove={onImageRemove}
                    isLoading={state.isLoading}
                    isUploading={state.isUploading}
                    onClose={() =>
                        updateState({
                            showDoc: false,
                            imageList: []
                        })
                    }
                />
            )}

            {screenState.showMailModal && (
                <MailDocModal
                    jobId={headerRecordId}
                    onClose={hideMail}
                    jobBasicDetail={dynamicFormData.jobBasicDetail}
                    docInfoToMail={screenState.docInfoToMail}
                    showToast={showToast}
                />
            )}
        </Grid>
    );
};
const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <RenderForm {...props} />
        </StateProvider>
    );
};

export default Screen;
