import React, { useContext, useMemo, useEffect } from 'react';
import { AppBar, Toolbar, Button, useScrollTrigger, Slide, MenuItem, MenuList, Paper, Divider, Collapse } from '@material-ui/core';
import { makeStyles, fade } from '@material-ui/core/styles';
import { Link, NavLink } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import Popover from '@material-ui/core/Popover';
import { SecondaryButton, CustomButton } from '../FormInput';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { getMenuForRole } from './_menu';
import AppContext from '../../App/AppContext';
import { AppStorage } from '../Service/storage-service';
import MySignature, { UserSignatureReminder } from '../Modal/SignatureModal';
import { postSignature, postSignatureStatus } from '../Service/login-service';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import { useHistory } from 'react-router-dom';
import { getDataByEwipNo } from '../Service/common-service';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import TemporaryDrawer from './Drawer';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Drawer from '@material-ui/core/Drawer';
import { useWindowSize } from '../Controls/ScreenResolution';
import { WorkflowDash, PartSales, UserProfile, AppraisalDashboard } from '../../Components';
import { Label } from 'recharts';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { TickerDisplayMessage } from './ticker';
import { TableContainer } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';
import formatters from '../Grid/inputFormatter';
import InternalMenusToConvertDynamic from '../Root/menu/roles-menu/reportingMenu';
import SettingsIcon from '@material-ui/icons/Settings';
import DialogComp from '../Modal/dialogModal';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.action.hover
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            cursor: 'pointer'
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    table: {
        width: 800
    },
    root: {
        flexGrow: 0
    },
    title: {
        flexGrow: 1,
        fontSize: '15px'
    },
    linknav: {
        textDecoration: 'none',
        color: (props) => `${props.portalMenuBarTextColour}`,
        backgroundColor: (props) => `${props.portalMenuBarBackgroundColour}`,
        fontSize: '15px'
    },
    labelBtn: {
        color: (props) => `${props.portalMenuBarTextColour}`,
        textTransform: 'none',
        textDecoration: 'none',
        fontSize: '15px'
    },
    logoutBtn: {
        display: 'inline-flex',
        VerticalAlign: 'text-bottom',
        BoxSizing: 'inherit',
        textAlign: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        cursor: 'pointer',
        marginLeft: 1
    },
    app_toolbar: {
        height: '45px',
        minHeight: '45px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 10px'
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        marginTop: (props) => (props.WindowWidths < 750 ? 9 : props.WindowWidths < 1500 ? 9 : 5),
        width: '200px !important',
        maxHeight: '30px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 0.9),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit'
    },
    inputInput: {
        padding: '5px 8px 8px 0px', //theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(2.5)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    },
    drawerPaper: {
        top: '60px',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    }
}));

function HideOnScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction={'down'} in={!trigger}>
            {children}
        </Slide>
    );
}

const AppbarComponent = (props) => {
    const [state, setState] = React.useState({ open2: null, WorkflowJobs: [], left: false, openSetting: false, open3: null });
    const {
        loggedIn,
        userRoles,
        displayName,
        lastLogin,
        showToast,
        portalSettings,
        showModal,
        showTopNavAndMenu,
        hideShowTopNav,
        isTopNav,
        tempMenus,
        reportingMenus,
        appraisalMenu
    } = useContext(AppContext);
    const [WindowWidths, WindowHeights] = useWindowSize();
    let tempPortalSettings = { ...portalSettings };
    tempPortalSettings.WindowWidths = WindowWidths;
    const classes = useStyles(tempPortalSettings);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);
    const [anchorEl4, setAnchorEl4] = React.useState(null);
    const [openSign, setOpenSign] = React.useState(false);
    const [openSignRem, setOpenSignRem] = React.useState(false);
    const [searchAnchorEl, setSearchAnchorEl] = React.useState(null);
    let history = useHistory();
    const isCommunicatorAllowed = AppStorage.getCommunicatorAllowed();
    const isInvoiceAllowed = AppStorage.getInvoiceAllowed();
    const isReportingAllowed = AppStorage.getReportingAllowed();
    const isWIPAllowed = AppStorage.getWIPAllowed() && portalSettings.portalSettingeWIPEnabled;
    let menuPermissionCounter = isCommunicatorAllowed + isInvoiceAllowed + isReportingAllowed + isWIPAllowed;
    const isReportingAgedMeasureAllowed = AppStorage.getReportingAgedMeasureAllowed();
    const isReportingPartsAllowed = AppStorage.getReportingPartsAllowed();

    useEffect(() => {
        if (loggedIn && AppStorage.getSignNotifiedStatus() === 0 && AppStorage.getSignatureStatus() != 1 && AppStorage.getSignatureStatus() != 2) {
            setOpenSignRem(true);
            AppStorage.setSignNotifiedStatus('set');
        }
    }, []);
    let isAdmin = userRoles.includes('admin');
    const isTechnician = userRoles.includes('technician');
    const getWipDataById = async (wipNo) => {
        const res = await getDataByEwipNo(wipNo);
        if (res.success) {
            setState((st) => ({ ...st, WorkflowJobs: res.data.jobsDetails }));
        } else {
            setState((st) => ({ ...st, WorkflowJobs: [] }));
        }
    };

    const handleChange = (event) => {
        if (event.target.value.length > 4) {
            getWipDataById(event.target.value);
            setSearchAnchorEl(event.currentTarget);
        } else {
            setSearchAnchorEl(null);
        }
    };

    const handleClick = (item) => (e) => {
        setState((st) => ({ ...st, [item]: !st[item], id2: item }));
        setAnchorEl2(e.currentTarget);
    };

    const handleCollaps = (item) => (e) => {
        setState((st) => ({ ...st, [item]: !st[item], id2: item }));
        setAnchorEl3(e.currentTarget);
    };

    const handleClose = (item, coll) => {
        setState((st) => ({ ...st, [item]: null }));
        if (!coll) {
            setAnchorEl2(null);
        } else {
            setAnchorEl3(null);
        }
    };

    const handleCollaps2 = (item) => (e) => {
        setState((st) => ({ ...st, [item]: !st[item] }));
        setAnchorEl4(e.currentTarget);
    };

    const CloseChildPopver = (item, coll, item2, item3) => {
        setState((st) => ({ ...st, [item]: null, [item2]: null, [item3]: null }));
        if (!coll) {
            setAnchorEl3(null);
            setAnchorEl2(null);
        } else {
            setAnchorEl4(null);
        }
    };

    const handleCloseSearch = (data) => {
        localStorage.setItem('currentWorkflowId', data.workFlowID);

        history.push(`/Job/${data.headerRecordID}/${data.workFlowID}`);
        setSearchAnchorEl(null);
        setState((st) => ({ ...st, WorkflowJobs: [] }));
    };

    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);

    const id = open ? 'simple-popover' : undefined;
    const handlePopupClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopupClose = () => {
        setAnchorEl(null);
    };
    const showPasswordChangeModal = (e) => {
        e.preventDefault();
        showModal('PasswordChange');
    };
    const Logout = () => {
        window.top.postMessage({ type: 'trigger-logout' }, '*');
        history.push(`/`);
    };
    const currentMenus = useMemo(() => {
        let menus = getMenuForRole(userRoles, loggedIn);

        if (isWIPAllowed) {
            let dashMenus = [];
            if (tempMenus.menus.length > 0) {
                dashMenus = tempMenus.menus.map((x) => {
                    return {
                        label: `${x.label}`,
                        path: `/dashboard/${x.id}?workFlowId=${x.id}&workFlowName=${x.label}`,
                        exact: true,
                        authType: 1,
                        component: () => {
                            return <WorkflowDash workFlowData={x} />;
                        }
                    };
                });
            }
            if (menuPermissionCounter == 1) {
                let isDash = menus.findIndex((p) => p.isDynamicChildren === 'eWipDashboard');
                if (tempMenus.menus.length > 0 && isDash >= 0) {
                    menus[isDash].children = dashMenus;
                }
            } else {
                let iseWIPMenu = menus.findIndex((p) => p.label === 'eWIP');
                let isDash = menus[iseWIPMenu]?.children.findIndex((p) => p.isDynamicChildren === 'eWipDashboard');
                if (tempMenus.menus.length > 0 && isDash >= 0) {
                    menus[iseWIPMenu].children[isDash].children = dashMenus;
                }
            }
        }
        if (isReportingAllowed) {
            let ReportsIndex = InternalMenusToConvertDynamic.findIndex((m) => m.label == 'Reports');
            let findAgedMeasureMenuIndex = InternalMenusToConvertDynamic[ReportsIndex]?.children?.findIndex((m) => m.label == 'Aged Measures');
            let tempAgedMeasureChildren = InternalMenusToConvertDynamic[ReportsIndex]?.children[findAgedMeasureMenuIndex]?.children;
            const agedMeasureMenus = [];
            const PartsMenus = [];
            if (reportingMenus?.menus?.length > 0) {
                let menusFromAPI = reportingMenus.menus;
                if (tempAgedMeasureChildren?.length > 0) {
                    for (let j = 0; j < menusFromAPI.length; j++) {
                        for (let i = 0; i < tempAgedMeasureChildren?.length; i++) {
                            if (tempAgedMeasureChildren[i].label === menusFromAPI[j].label) {
                                agedMeasureMenus.push({
                                    ...tempAgedMeasureChildren[i],
                                    path: menusFromAPI[j].path,
                                    label: menusFromAPI[j]?.menuLabel || menusFromAPI[j]?.label || ''
                                });
                            }
                        }
                    }
                }
                for (let j = 0; j < menusFromAPI.length; j++) {
                    if (menusFromAPI[j].parentMenu == 'Part') {
                        PartsMenus.push({
                            exact: true,
                            authType: 1,
                            path: menusFromAPI[j].path,
                            label: menusFromAPI[j]?.menuLabel || menusFromAPI[j]?.label || '',
                            component: () => {
                                return <PartSales />;
                            }
                        });
                    }
                }
            }
            let isReportingMenu = menus.findIndex((p) => p.label === 'Reports');
            if (isReportingMenu > -1) {
                if (isReportingAgedMeasureAllowed || isReportingPartsAllowed) {
                    let isReportingMenu = menus.findIndex((p) => p.label === 'Reports');
                    if (isReportingMenu >= 0) {
                        let agedMeasuresIndex = menus[isReportingMenu]?.children.findIndex((p) => p.label === 'Aged Measures');
                        let partsIndex = menus[isReportingMenu]?.children.findIndex((p) => p.label === 'Parts');
                        if (isReportingAgedMeasureAllowed && agedMeasuresIndex >= 0) {
                            menus[isReportingMenu].children[agedMeasuresIndex].children = agedMeasureMenus.length > 0 ? agedMeasureMenus : [];
                        }
                        if (isReportingPartsAllowed && partsIndex >= 0) {
                            menus[isReportingMenu].children[partsIndex].children = PartsMenus.length > 0 ? PartsMenus : [];
                        }
                    }
                }
            }
            // if (appraisalMenu.menus.length > 0) {
            //     let aprrmenusFromAPI = appraisalMenu.menus;
            //     for (let j = 0; j < aprrmenusFromAPI.length; j++) {
            //         if (aprrmenusFromAPI[j].parentMenu == 'Appraisal') {
            //             AppraisalMenus.push({
            //                 exact: true,
            //                 authType: 1,
            //                 path: aprrmenusFromAPI[j].path,
            //                 label: aprrmenusFromAPI[j]?.menuLabel || aprrmenusFromAPI[j]?.label || '',
            //                 component: () => {
            //                     return <AppraisalDashboard />;
            //                 }
            //             });
            //         }
            //     }
            // }
            // let isAppraisalMenu = menus.findIndex((p) => p.label === 'Appraisal');
            // menus[isAppraisalMenu].children = AppraisalMenus.length > 0 ? AppraisalMenus : [];
            // console.log(menus);
        }
        return menus;
    }, [loggedIn, tempMenus, reportingMenus, appraisalMenu]);

    const closeSign = () => {
        setOpenSign(!openSign);
    };
    const closeSignRem = () => {
        setOpenSignRem(false);
    };

    const postSignData = async (sign, id, defaultInternalDashboard, appbarLocation) => {
        const data = {
            signature: sign,
            AppbarLocation: appbarLocation,
            defaultInternalDashboard: defaultInternalDashboard
        };
        console.log('from appbar ');
        let resSign = await postSignature(data);
        let resStatus = id && (await postSignatureStatus(id));
        if (resSign) {
            if (resSign?.success) {
                AppStorage.setSignature(sign);
                AppStorage.setUserGridColumnAppbarLocation(appbarLocation);

                localStorage.setItem('_defaultInternalDashboard_', defaultInternalDashboard);
                showToast('User Settings Updated Successfully!');
            } else {
                showToast(resSign?.message || 'Something went wrong!');
            }
        }
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handler = (children, isFiltered) => {
        const sortChildren = (items) => items.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

        let currentNav = isFiltered ? _.orderBy(children, [(c) => c.label.toLowerCase()], ['asc']) : children;
        return currentNav.map((m, index) => {
            if (!m.children) {
                return (
                    <NavLink key={m.label} activeClassName="active-link" exact to={m.path} className={classes.linknav}>
                        <Button variant="text" className={classes.labelBtn} to={m.path}>
                            {m.label}
                        </Button>
                    </NavLink>
                );
            }
            return (
                <div key={m.label + 1} style={{ display: 'inline' }}>
                    <Button aria-describedby={m.label} variant="text" onClick={handleClick(m.label)} title={m.label} className={classes.labelBtn}>
                        {m.label}
                        {state[m.label] ? <ExpandLess /> : <ExpandMore />}
                    </Button>
                    <Popover
                        id={m.label}
                        open={Boolean(state[m.label])}
                        anchorEl={anchorEl2}
                        onClose={() => handleClose(m.label)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => handleClose(m.label)}>
                                <MenuList className={classes.linknav} autoFocusItem={open2} id="composition-menu" aria-labelledby="composition-button">
                                    {m.children.map((n, index) => {
                                        if (n.children) {
                                            return (
                                                <>
                                                    <MenuItem onClick={handleCollaps(n.label)} className={classes.labelBtn}>
                                                        <div style={{ width: '100%' }}>
                                                            <Button
                                                                className={classes.labelBtn}
                                                                style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                                                            >
                                                                {n.label}

                                                                {!state[n.label] ? <ChevronRightIcon style={{ float: 'right' }} /> : <ChevronLeftIcon />}
                                                            </Button>
                                                        </div>
                                                    </MenuItem>
                                                    <Popover
                                                        id={n.label}
                                                        anchorOrigin={{
                                                            vertical: 'center',
                                                            horizontal: 'right'
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'center',
                                                            horizontal: 'left'
                                                        }}
                                                        onClose={() => handleClose(n.label, true)}
                                                        anchorEl={anchorEl3}
                                                        open={Boolean(state[n.label])}
                                                    >
                                                        <MenuList
                                                            className={classes.linknav}
                                                            autoFocusItem={open3}
                                                            id="composition-menu2"
                                                            aria-labelledby="composition-button2"
                                                        >
                                                            {sortChildren(n.children).map((a) => {
                                                                if (a.children) {
                                                                    return (
                                                                        <>
                                                                            <MenuItem onClick={handleCollaps2(a.label)} className={classes.labelBtn}>
                                                                                <div style={{ width: '100%' }}>
                                                                                    <Button
                                                                                        aria-describedby={`${a.label}id`}
                                                                                        className={classes.labelBtn}
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            justifyContent: 'space-between',
                                                                                            width: '100%'
                                                                                        }}
                                                                                    >
                                                                                        {a.label}

                                                                                        {!state[a.label] ? (
                                                                                            <ChevronRightIcon style={{ float: 'right' }} />
                                                                                        ) : (
                                                                                            <ChevronLeftIcon />
                                                                                        )}
                                                                                    </Button>
                                                                                </div>
                                                                            </MenuItem>
                                                                            <Popover
                                                                                id={`${a.label}id`}
                                                                                anchorOrigin={{
                                                                                    vertical: 'center',
                                                                                    horizontal: 'right'
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: 'center',
                                                                                    horizontal: 'left'
                                                                                }}
                                                                                onClose={() => CloseChildPopver(a.label, true, n.label, m.label)}
                                                                                anchorEl={anchorEl4}
                                                                                open={Boolean(state[a.label])}
                                                                            >
                                                                                <MenuList
                                                                                    className={classes.linknav}
                                                                                    autoFocusItem={open3}
                                                                                    id="composition-menu2"
                                                                                    aria-labelledby="composition-button2"
                                                                                >
                                                                                    {sortChildren(a.children).map((k) => (
                                                                                        <MenuItem
                                                                                            key={k.label}
                                                                                            onClick={() => CloseChildPopver(a.label, true, n.label, m.label)}
                                                                                            className={classes.labelBtn}
                                                                                        >
                                                                                            <NavLink
                                                                                                key={k.label}
                                                                                                className={classes.linknav}
                                                                                                activeClassName="active-link"
                                                                                                exact
                                                                                                to={k.path}
                                                                                            >
                                                                                                {k.label}
                                                                                            </NavLink>
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </MenuList>
                                                                            </Popover>
                                                                        </>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <MenuItem key={a.label} onClick={() => handleClose(m.label, true)}>
                                                                            <NavLink
                                                                                key={a.label}
                                                                                className={classes.labelBtn}
                                                                                activeClassName="active-link"
                                                                                exact
                                                                                to={a.path}
                                                                            >
                                                                                <Button
                                                                                    variant="text"
                                                                                    fullWidth
                                                                                    className={classes.labelBtn}
                                                                                    to={a.path}
                                                                                    onClick={() => handleClose(n.label, true)}
                                                                                >
                                                                                    {a.label}
                                                                                </Button>
                                                                            </NavLink>
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            })}
                                                        </MenuList>
                                                    </Popover>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <MenuItem key={n.label} onClick={() => handleClose(m.label)}>
                                                    <NavLink key={n.label} className={classes.labelBtn} activeClassName="active-link" exact to={n.path}>
                                                        <Button variant="text" fullWidth className={classes.labelBtn} to={n.path}>
                                                            {n.label}
                                                        </Button>
                                                    </NavLink>
                                                </MenuItem>
                                            );
                                        }
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                        {/* </Collapse> */}
                    </Popover>
                </div>
            );
        });
    };

    return (
        <div className={classes.root}>
            <HideOnScroll {...props}>
                <AppBar
                    position="static"
                    style={{ height: '45px', backgroundColor: portalSettings.portalMenuBarBackgroundColour, color: portalSettings.portalMenuBarTextColour }}
                >
                    <Toolbar className={classes.app_toolbar}>
                        <div style={{ display: 'flex' }}>
                            {WindowWidths < 1620 && loggedIn ? (
                                <div>
                                    <IconButton
                                        onClick={toggleDrawer('left', true)}
                                        edge="start"
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="menu"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </div>
                            ) : (
                                <div style={{ padding: '0px', display: 'inline' }}>
                                    {handler(
                                        currentMenus.filter((m) => !m.hidden),
                                        false
                                    )}
                                </div>
                            )}
                            {loggedIn && !isAdmin && isWIPAllowed && !isTechnician && (
                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder="Search by WIP/Reg"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput
                                        }}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                    <Popper open={Boolean(searchAnchorEl)} anchorEl={searchAnchorEl} placement="bottom-start" transition style={{ zIndex: 99 }}>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper style={{ borderRadius: '5px', maxHeight: '350px', overflow: 'auto' }}>
                                                    <TableContainer>
                                                        <Table className={classes.table} aria-label="customized table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell width="9%">
                                                                        <b>WIP No</b>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell width="10%">
                                                                        <b>Vehicle Reg</b>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell width="15%">
                                                                        <b>Customer Name</b>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell width="9%">
                                                                        <b>Workflow</b>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell width="13%">
                                                                        <b>Return Reason</b>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell width="9%">
                                                                        <b>Branch</b>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell width="19%">
                                                                        <b>Due Date In</b>
                                                                    </StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {/* {console.log(state.WorkflowJobs)} */}
                                                                {state.WorkflowJobs.map((w, index) => {
                                                                    return (
                                                                        <StyledTableRow key={index} onClick={() => handleCloseSearch(w)}>
                                                                            <StyledTableCell component="th" scope="row">
                                                                                <div>{w.wipNumber}</div>
                                                                            </StyledTableCell>
                                                                            <StyledTableCell component="th" scope="row">
                                                                                <div>{w.regNumber}</div>
                                                                            </StyledTableCell>
                                                                            <StyledTableCell component="th" scope="row">
                                                                                <div>{w.customer}</div>
                                                                            </StyledTableCell>
                                                                            <StyledTableCell component="th" scope="row">
                                                                                <div>{w.workflow}</div>
                                                                            </StyledTableCell>
                                                                            <StyledTableCell component="th" scope="row">
                                                                                <div>{w.returnReason}</div>
                                                                            </StyledTableCell>
                                                                            <StyledTableCell component="th" scope="row">
                                                                                <div>{w.branch}</div>
                                                                            </StyledTableCell>
                                                                            <StyledTableCell component="th" scope="row">
                                                                                <div>{formatters.DateTimeFormatter(w.dateDueIn)}</div>
                                                                            </StyledTableCell>
                                                                        </StyledTableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                </div>
                            )}
                        </div>
                        <div style={{ display: 'inline-block' }}>
                            {loggedIn ? (
                                <div style={{ display: 'flex' }}>
                                    <Grid style={{ marginRight: 10, display: 'inline-flex', marginTop: -5 }}>
                                        <TickerDisplayMessage />
                                    </Grid>
                                    <div>
                                        {isTopNav ? (
                                            <CustomButton
                                                icon={KeyboardArrowUpIcon}
                                                onClick={() => hideShowTopNav(false)}
                                                toolTipTitle="Hide Portal Info"
                                                IconColor="white"
                                            />
                                        ) : (
                                            <CustomButton
                                                icon={KeyboardArrowDownIcon}
                                                onClick={() => hideShowTopNav(true)}
                                                toolTipTitle="Show Portal Info"
                                                IconColor="white"
                                            />
                                        )}
                                    </div>
                                    <div aria-describedby={id} onClick={handlePopupClick} className={classes.logoutBtn}>
                                        <span className={classes.labelBtn}>{displayName}</span>
                                        <ExpandMore
                                        // style={{ marginTop: '-5px' }}
                                        />
                                    </div>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handlePopupClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center'
                                        }}
                                    >
                                        <MenuList className={classes.linknav}>
                                            <MenuItem onClick={Logout} className={`${classes.logoutBtn} ${classes.labelBtn}`}>
                                                {/* <Link to="/logout" className={`${classes.logoutBtn} ${classes.labelBtn}`}> */}
                                                <ExitToAppIcon style={{ marginRight: '4px' }} />
                                                <span>Logout</span>
                                                {/* </Link> */}
                                            </MenuItem>
                                            <MenuItem onClick={() => setState((st) => ({ ...st, openSetting: true }))} className={classes.labelBtn}>
                                                {/* <Link to="/logout" className={`${classes.logoutBtn} ${classes.labelBtn}`}> */}
                                                <SettingsIcon style={{ marginRight: '4px' }} />
                                                <span>Settings</span>
                                                {/* </Link> */}
                                            </MenuItem>
                                            {/* {!isAdmin && (
                                                <MenuItem className={classes.labelBtn} onClick={closeSign}>
                                                    <BorderColorIcon style={{ marginRight: '4px' }} />
                                                    <span onClick={closeSign}>My Settings</span>
                                                </MenuItem>
                                            )}
                                            <MenuItem className={classes.labelBtn}>
                                                <VpnKeyIcon style={{ marginRight: '4px' }} />
                                                <span onClick={showPasswordChangeModal}>Change Password</span>
                                            </MenuItem> */}
                                            <MenuItem className={classes.labelBtn} disabled>
                                                {/* <VpnKeyIcon style={{ marginRight: '4px' }} /> */}
                                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                <span>{`Version ${window._ENV_.app_version}`}</span>
                                            </MenuItem>
                                        </MenuList>
                                    </Popover>
                                </div>
                            ) : (
                                <Link to="/login" style={{ textDecoration: 'none', height: '35px' }}>
                                    <SecondaryButton style={{ height: '35px', fontSize: '15px' }}>Login</SecondaryButton>
                                </Link>
                            )}
                        </div>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            {openSign && (
                <DialogComp title={`Settings`} onClose={() => setOpenSign(false)} fullWidth maxWidth="md">
                    <MySignature onClose={closeSign} postData={postSignData} />
                </DialogComp>
            )}
            {!isAdmin && openSignRem && <UserSignatureReminder closeSign={closeSign} onClose={closeSignRem} postData={postSignData} />}

            <Drawer
                anchor={'left'}
                open={state.left}
                onClose={toggleDrawer('left', false)}
                PaperProps={{ className: classes.drawerPaper }}
                BackdropProps={{ style: { top: '60px' } }}
            >
                <TemporaryDrawer currentMenus={currentMenus} onClose={toggleDrawer} />
            </Drawer>

            {state.openSetting ? (
                <DialogComp title={`Settings`} onClose={() => setState((st) => ({ ...st, openSetting: false }))} fullWidth maxWidth="xl">
                    <UserProfile />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default AppbarComponent;
