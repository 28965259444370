import React, { useReducer } from 'react';
import { Grid, Typography, InputLabel, RadioGroup, FormControlLabel, Radio, Checkbox, FormHelperText, Box, IconButton, Chip, Avatar } from '@material-ui/core';
import { reducer, initState } from '../../../Reducer/action';
import { SecondaryButton, TextBox, YesNoButton, SlideToggle, Dialogs, DialogTitles, DialogContents, DialogsActions } from '../../../../Core/FormInput/index';
import { useState, useCallback } from 'react';
import CommonGijgoGrid from '../../../../Core/Controls/GijgoGrid/index';
import moment from 'moment';
import SelectBox from './../../../../Core/FormInput/SelectBox';
import DataGridComp from '../../../../Core/Grid';
import { GridOverlay } from '@material-ui/data-grid';
import { getStatusDropdown, postVehicleStatus, getVehicleStatusID } from '../../../../Core/Service/communicator-service';
import { useEffect } from 'react';
import { useContext } from 'react';
import AppContext from '../../../../App/AppContext';
import VehicleStatusStepper from '../VehicleStatusStepper/vehicleStatusStepper';
import CloseIcon from '@material-ui/icons/Close';
import BuildIcon from '@material-ui/icons/Build';
import LogHistory from '../OpenActionLogs/logHistory';
import ApprovalDetailsModal from './approvalDetailsModal';
import DialogComp from '../../../../Core/Modal/dialogModal';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const AddEditSiteVehicle = (props) => {
    const { showToast, showModal, userRoles } = useContext(AppContext);
    const isCustomer = userRoles.includes('customer');
    let isReadOnly = isCustomer;
    const { onClose } = props;
    const [state, setState] = useState({
        errors: {},
        partStatusList: [],
        vehicleStatusList: [],
        otherVehicleStatusList: [],
        partStatus: '',
        vehicleStatus: '',
        highPriority: false,
        stepperNumber: 0,
        stepperData: {}
    });

    const pullData = async () => {
        let getByIdRes = await getVehicleStatusID(props.headerRecordID);
        let stepperData = getByIdRes.data?.jobProgressDate ? getByIdRes.data?.jobProgressDate[0] : {};
        let data = getByIdRes.data.list[0];
        if (getByIdRes.success) {
            let CheckDate = data?.cancelledDate || data?.dateCollected;
            setState((st) => ({
                ...st,
                headerRecordID: data?.headerRecordID,
                internalNotes: data?.internalNotes,
                partETA: data?.partETA,
                partStatus: data?.partStatus,
                vehicleDeliveryDate: data?.vehicleDeliveryDate,
                vehicleStatus: data?.vehicleStatus,
                workDetails: data?.workDetails,
                highPriority: data?.highPriority,
                stepperNumber: data?.stepperNumber,
                currentStepperNumber: data?.stepperNumber - 1,
                statusDesc: data?.status,
                bookedDate: data?.bookedDate,
                cancelledDate: data?.cancelledDate,
                dateCollected: data?.dateCollected,
                approvedOrderDate: data?.approvedOrderDate,
                stepperData: stepperData,
                isReadOnly: isReadOnly || CheckDate ? true : false
            }));
        }
    };

    useEffect(async () => {
        pullData();
    }, []);

    const stepperModalOnClose = (res) => {
        res && pullData();
        res && props.onClose(true);
    };

    useEffect(async () => {
        if (state.vehicleStatus) {
            let res = await getStatusDropdown(state.vehicleStatus);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    partStatusList: res?.data?.partStatus,
                    vehicleStatusList: res?.data?.vehicleStatus,
                    otherVehicleStatusList: res?.data?.vehicleStatus
                        .map((q) => {
                            if (q.smsText) {
                                q.smsText = q.smsText.replace('{reg}', `${props.reg}`);
                            }

                            return { ...q };
                        })
                        .filter((p) => p.id != state.vehicleStatus)
                }));
            }
        }
    }, [state.vehicleStatus]);

    const handleInput = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setState((st) => ({ ...st, [name]: checked }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };

    const validation = () => {
        let { workDetails, vehicleStatus, partStatus } = state;
        let isValid = true;
        let errors = {};

        if (!workDetails) {
            isValid = false;
            errors.workDetails = 'Work Details is required';
        }
        // if (!vehicleStatus) {
        //     isValid = false;
        //     errors.vehicleStatus = 'Vehicle Status is required';
        // }
        // if (!partStatus) {
        //     isValid = false;
        //     errors.partStatus = 'Part Status is required';
        // }
        setState((st) => ({ ...st, errors: errors }));
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            let data = {
                headerRecordID: props.headerRecordID,
                workDetails: state.workDetails,
                // internalNotes: state.internalNotes,
                // vehicleStatus: state.vehicleStatus,
                // vehicleDeliveryDate: state.vehicleDeliveryDate,
                // partStatus: state.partStatus,
                // partETA: state.partETA,
                highPriority: state.highPriority
            };
            let res = await postVehicleStatus(data);
            if (res.success) {
                showToast(`Vehicle Status Updated Successfully`);
                props.onClose(true);
            } else {
                showToast(`Vehicle Status Failed To Update`);
            }
        }
    };

    const handleHistoryModal = (val) => {
        setState((st) => ({ ...st, history: true }));
    };
    const closeHistoryModal = (val) => {
        setState((st) => ({ ...st, history: false }));
    };
    const handleapprovalDetailModal = (val) => {
        setState((st) => ({ ...st, approvalDetailShow: true }));
    };
    const closeapprovalDetailModal = (val) => {
        setState((st) => ({ ...st, approvalDetailShow: false }));
    };

    const addActionLogHeaderComp = (ModalTitle) => {
        return (
            <Grid container>
                <Grid item>
                    <span>
                        Vehicle Status
                        {props.isShowHistory && (
                            <Chip
                                style={{ marginLeft: 10 }}
                                avatar={
                                    <Avatar>
                                        <BuildIcon />
                                    </Avatar>
                                }
                                label="History"
                                onClick={handleHistoryModal}
                            />
                        )}
                        {state.approvedOrderDate && (
                            <Chip
                                style={{ marginLeft: 10 }}
                                avatar={
                                    <Avatar>
                                        <AssignmentTurnedInIcon />
                                    </Avatar>
                                }
                                label="Approval Details"
                                onClick={handleapprovalDetailModal}
                            />
                        )}
                    </span>
                </Grid>
            </Grid>
        );
    };

    return (
        <Dialogs open={true} onClose={() => props.onClose(false)} maxWidth="lg">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{<DialogTitles>{addActionLogHeaderComp()}</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={() => props.onClose(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="space-between">
                            <Grid item style={{ fontSize: 15, textAlign: 'left' }}>
                                WIP No:&nbsp; <b> {props.wip}</b> &nbsp;&nbsp; Reg. No:&nbsp; <b> {props.reg}</b>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>High Priority</Grid>
                                    <Grid item>
                                        <SlideToggle name="highPriority" checked={state.highPriority} onChange={handleInput} disabled={state.isReadOnly} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <VehicleStatusStepper
                            currentStatus={state.currentStepperNumber}
                            vehicleStatusList={state.otherVehicleStatusList}
                            headerRecordID={props.headerRecordID || state.headerRecordID}
                            onClose={stepperModalOnClose}
                            stepperData={state.stepperData}
                            partStatusList={state.partStatusList}
                            statusDesc={state.statusDesc}
                            bookedDate={state.bookedDate}
                            cancelledDate={state.cancelledDate}
                            dateCollected={state.dateCollected}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel shrink error>
                            Work Details
                        </InputLabel>
                        <TextBox
                            placeholder=" Work Details"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            variant="outlined"
                            name="workDetails"
                            value={state.workDetails}
                            onChange={handleInput}
                            disabled={state.isReadOnly}
                        />
                        <FormHelperText error>{state.errors.workDetails}</FormHelperText>
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <Grid item xs={6}>
                    <SecondaryButton className="Submitbtn" fullWidth onClick={handleSubmit} disabled={state.isReadOnly}>
                        Submit
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton className="Submitbtn" fullWidth onClick={() => onClose(false)}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </DialogsActions>
            {state.history ? (
                <LogHistory
                    headerRecordID={props.headerRecordID}
                    reg={props.reg}
                    wip={props.wip}
                    onClose={() => closeHistoryModal(false)}
                    hideAddActionBtn={true}
                />
            ) : null}

            {state.approvalDetailShow ? (
                <DialogComp title={'Approval Details'} onClose={() => closeapprovalDetailModal(false)} fullWidth maxWidth="sm">
                    <ApprovalDetailsModal
                        headerRecordID={props.headerRecordID}
                        reg={props.reg}
                        wip={props.wip}
                        onClose={() => closeapprovalDetailModal(false)}
                        hideAddActionBtn={true}
                    />
                </DialogComp>
            ) : null}
        </Dialogs>
    );
};

export default AddEditSiteVehicle;
