import React from 'react';
import { Grid } from '@material-ui/core';
import { TextBoxShrink, SlideToggle } from '../../../../Core/FormInput';
import { get } from 'lodash';

export const VehicleDetails = React.memo((props) => {
    const { state, setState } = props; //useContext(JobSheetContext);
    const setField = (e) => {
        const { name, type, value, valueAsNumber, checked } = e.target;
        if (type === 'number') {
            setState((st) => ({ ...st, [name]: valueAsNumber }));
        } else if (type === 'checkbox') {
            setState((st) => ({ ...st, [name]: checked }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };
    const getField = (name) => {
        return get(state, name);
    };
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 20 }}
                    onChange={setField}
                    value={getField('service24RegNumber')}
                    label="Reg No"
                    name="service24RegNumber"
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 20 }}
                    onChange={setField}
                    value={getField('service24VehicleType')}
                    label="Type"
                    name="service24VehicleType"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 20 }}
                    onChange={setField}
                    value={getField('service24ChassisNo')}
                    label="ChassisNo"
                    name="service24ChassisNo"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 20 }}
                    onChange={setField}
                    value={getField('service24EngineNo')}
                    label="Engine No"
                    name="service24EngineNo"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink type="Date" onChange={setField} value={getField('service24RegDate')} label="Reg Date" name="service24RegDate" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={6}>
                        <TextBoxShrink
                            type="number"
                            onChange={setField}
                            value={getField('service24OdometerReading')}
                            label="Mileage (M/Km)"
                            name="service24OdometerReading"
                        />
                    </Grid>
                    {console.log(getField('service24OdometerUnit'), 'getField')}
                    <Grid item xs={6}>
                        <SlideToggle
                            leftLabel="Miles"
                            rightLabel="KM"
                            name="service24OdometerUnit"
                            checked={getField('service24OdometerUnit')}
                            onChange={setField}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <TextBoxShrink
                    type="Date"
                    onChange={setField}
                    value={getField('service24NextServiceDue')}
                    label="Next Service Due"
                    name="service24NextServiceDue"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
                <TextBoxShrink inputProps={{ maxLength: 50 }} onChange={setField} value={getField('service24Colour')} label="Colour" name="service24Colour" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
                <TextBoxShrink type="number" onChange={setField} value={getField('service24GVW')} label="GVW" name="service24GVW" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink inputProps={{ maxLength: 50 }} onChange={setField} value={getField('service24Load')} label="Load" name="service24Load" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 50 }}
                    onChange={setField}
                    value={getField('service24Destination')}
                    label="Destination"
                    name="service24Destination"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 50 }}
                    onChange={setField}
                    value={getField('service24TrailerOwner')}
                    label="Trailer Owner"
                    name="service24TrailerOwner"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextBoxShrink
                    inputProps={{ maxLength: 20 }}
                    onChange={setField}
                    value={getField('service24TrailerNo')}
                    label="Trailer No"
                    name="service24TrailerNo"
                />
            </Grid>
        </Grid>
    );
});
