import { get, post, put } from './http-calls';
import { memoize } from 'lodash';

export const getWorkflowListsAndOps = async (workflowId) => {
    if (!workflowId) {
        console.error("param [workflowId] can't be NULL or undefined.");
        return { success: false };
    }
    let res = await get(`WorkflowStepList/ByWorkflowId/${workflowId}`, { useAuthToken: true });
    return res;
};
export const getWorkflowStepList = memoize(async () => {
    let res = await get(`WorkflowStepList/`, { useAuthToken: true });
    return res;
});

export const getWebForms = async (ProcessID) => {
    let res = await get(`WebForms?Id=${ProcessID}`, { useAuthToken: true });
    return res;
};
export const addWorkflowSteps = async (workflowId, id, data, workflowType) => {
    let res = await post(
        `WorkflowSteps/${workflowId}`,
        {
            StepId: id,
            StepLabel: data.Label,
            StepIndex: data.sequence,
            StepListId: data.stepListId,
            OcrDocumentId: data.documentName,
            WebFormTypeId: data.webFormId,
            IsVisible_Customer: data.IsVisibleCutomer,
            IsVisible_Auditor: data.IsVisibleAuditor,
            IsEditable: data.IsEditable,
            IsRequired: data.IsRequired,
            JsonPropertyName: data.jsonProperty,
            IsDeleted: data.IsActive ? false : true,
            IsTextboxType: workflowType.isTextboxType,
            DynamicFormID: data.dynamicFormID
        },
        { useAuthToken: true }
    );
    return res;
};
export const getWorkflowListOps = async (id) => {
    let res = await get(`WorkflowStepList/${id}`, { useAuthToken: true });
    return res;
};

export const addWorkflowStepList = async (name) => {
    let res = await post(
        `WorkflowStepList`,
        {
            ListName: name
        },
        { useAuthToken: true }
    );
    return res;
};

export const updateWorkflowStepList = async (id, name) => {
    let res = await put(
        `WorkflowStepList/${id}`,
        {
            ListName: name
        },
        { useAuthToken: true }
    );
    return res;
};

export const addWorkflowStepsListOptions = async (listId, data) => {
    let res = await post(`WorkflowStepList/${listId}`, data, { useAuthToken: true });
    return res;
};

export const getWorkflowDataScreenDropdown = memoize(async () => {
    let res = await get(`workflowData/GetWorkflowDataScreenDropdown`, { useAuthToken: true });
    return res;
});
