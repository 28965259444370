import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PrimaryButton, PrimaryCheckbox, SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { AttachFile } from '@material-ui/icons';
import { downloadDocuments, mailDocument } from '../../../../Core/Service/workflowJobDocument-service';
import { CircularProgress, FormControlLabel, Grid } from '@material-ui/core';
import { ExpBaseURL } from '../../../../Core/Service/http-calls';
import moment from 'moment';
// import { ExpBaseURL } from './../../../Core/Service/http-calls';

const DownloadDocModal = (props) => {
    const wip = props.jobBasicDetail?.wipNumber;

    const [state, setState] = useState({});
    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const sendDoc = async (isMultiVersion) => {
        setState((st) => ({ ...st, sending: true }));
        let data = { ...props.docInfoToMail };
        let { docName, createdAt } = data;
        let res = await downloadDocuments(
            { ...data, isDownloadDoc: true, SendAllVersions: isMultiVersion },
            `${docName}-${wip}-${moment(createdAt).format('DD-MMM-YYYY HH:mm')}`
        );
        if (res.success) {
            props.onClose();
        }
        setState((st) => ({ ...st, sending: false }));
        props.showToast(res.message);
    };
    const allSendDoc = async (isMultiVersion) => {
        setState((st) => ({ ...st, sending: true }));
        let data = { ...props.docInfoToMail };
        let { docName, createdAt } = data;
        let res = await downloadDocuments(
            { ...data, isDownloadDoc: true, SendAllVersions: isMultiVersion },
            `${docName}-${wip}-${moment(createdAt).format('DD-MMM-YYYY HH:mm')}`
        );
        if (res.success) {
            setState((st) => ({ ...st, recivedFileName: isMultiVersion ? res?.message : '' }));
        }
        setState((st) => ({ ...st, sending: false }));
    };

    return (
        <Dialog open={true} onClose={handleClose} maxWidth="sm" fullWidth className="dialog-custom">
            <DialogTitle>
                <span>Download Document</span>
                <br />
                {` ${wip}-(${props.docInfoToMail.docName})`}
                <div className="title-header-actions">
                    <span className="close-icon" onClick={handleClose}>
                        &times;
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12}>
                    {state.recivedFileName ? (
                        <>
                            Click <a href={`${ExpBaseURL}/download-mailed-document/${state.recivedFileName}`}>here</a> to download
                        </>
                    ) : null}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SecondaryButton fullWidth onClick={() => sendDoc(false)}>
                            Current Version
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={6}>
                        <SecondaryButton fullWidth onClick={() => allSendDoc(true)}>
                            All Versions
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default DownloadDocModal;
