import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import BookIcon from '@material-ui/icons/Book';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

import Typography from '@material-ui/core/Typography';
import BookedStatusModal from './statusModals/booked';
import DialogComp from '../../../../Core/Modal/dialogModal';
import ArrivedStatusModal from './statusModals/vehicleArrived';
import AwaitingStatusModal from './statusModals/statusModals/collected';
import OnSiteStatusModal from './statusModals/onSite';
import formatters from '../../../../Core/Grid/inputFormatter';
import WorkCompletedStatusModal from './statusModals/workCompleted';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 15
    },
    active: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    completed: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 25,
        height: 25,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ['@media (max-width:780px)']: {
            width: (active) => (active ? 35 : 25),
            height: (active) => (active ? 35 : 25),
            marginTop: (active) => (active ? '0px' : '4px')
        }
    },
    active: {
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    },
    completed: {
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
    },
    icons: {
        fontSize: '1.0rem',
        ['@media (max-width:780px)']: {
            fontSize: (active) => (active ? '1.0rem' : '0.5rem')
        }
    }
});

const ColorlibStepIcon = (props) => {
    const { active, completed, stepCount } = props;
    const classes = useColorlibStepIconStyles(active);

    const icons = [
        <BookIcon className={classes.icons} />,
        <DriveEtaIcon className={classes.icons} />,
        <HomeWorkIcon className={classes.icons} />,
        <CheckIcon className={classes.icons} />,
        <DirectionsRunIcon className={classes.icons} />,
        <VideoLabelIcon className={classes.icons} />
    ];

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}
        >
            {icons[props.icon - 1]}
        </div>
    );
};

const ColorlibStepIconWrapper = (stepCount) => {
    return (props) => <ColorlibStepIcon stepCount={stepCount} {...props} />;
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export default function VehicleStatusStepper(props) {
    const classes = useStyles();

    const [activeStep, setActiveStep] = React.useState(0);

    const [step, setStep] = React.useState({ statusShow: false, show: false, steps: [] });

    useEffect(async () => {
        let tempFilterList = [];
        let TempStep = [
            {
                title: 'Booked',
                isBtn: false,
                value: formatters.DateTimeFormatter(props.bookedDate)
            },
            {
                title: 'Vehicle Arrived',
                isBtn: props.data?.leadLiveDate ? null : <AddIcon fontSize="small" />,
                value: formatters.DateTimeFormatter(props.stepperData.dateArrived)
            },
            {
                title: `On Site`,
                isBtn: props.data?.leadLiveDate ? null : <AddIcon fontSize="small" />,
                value: `${
                    props.currentStatus == 2
                        ? `${props.stepperData.workInProgress ? `${formatters.DateTimeFormatter(props.stepperData.workInProgress)}` : ''} (${props.statusDesc})`
                        : `${props.stepperData.workInProgress}`
                }`
            },
            {
                title: 'Work Completed',
                isBtn: props.data?.leadLiveDate ? null : <AddIcon fontSize="small" />,
                value: `${
                    props.stepperData.workCompleted
                        ? `${formatters.DateTimeFormatter(props.stepperData.workCompleted)} ${props.stepperData.workCompleted ? `(${props.statusDesc})` : ``}`
                        : ''
                }`
            },
            {
                title: 'Awaiting Collection',
                isBtn: props.data?.leadLiveDate ? null : <AddIcon fontSize="small" />,
                value: ''
            },
            {
                title: 'Collected',
                isBtn: props.data?.leadLiveDate ? null : <AddIcon fontSize="small" />,
                value: formatters.DateTimeFormatter(props.stepperData.dateCollected)
            }
        ];
        setActiveStep(props.currentStatus || 0);
        if (props.currentStatus == 5) {
            if (props.cancelledDate) {
                TempStep[5].title = props.statusDesc;
                TempStep[5].value = formatters.DateTimeFormatter(props.cancelledDate);
            }
            tempFilterList = TempStep.filter((p) => p.value);
        } else {
            tempFilterList = TempStep;
        }

        setStep((st) => ({ ...st, steps: tempFilterList }));
    }, [props.currentStatus, props.stepperData]);

    const closeModal = (res) => {
        setStep((st) => ({
            ...st,
            isBookedModal: false,
            isArrivedModal: false,
            isOnSiteModal: false,
            isAwaitingModal: false,
            isWorkCompletedModal: false,
            statusShow: false
        }));
        res && props.onClose(res);
    };

    const popUpHandler = (label) => {
        if (label === 'Booked') {
            setStep((st) => ({
                ...st,
                isBookedModal: true
            }));
        }
        if (label === 'Vehicle Arrived') {
            setStep((st) => ({
                ...st,
                isArrivedModal: true
            }));
        }
        if (label === 'On Site') {
            setStep((st) => ({
                ...st,
                isOnSiteModal: true
            }));
        }
        if (label === 'Work Completed') {
            setStep((st) => ({
                ...st,
                isWorkCompletedModal: true
            }));
        }
        if (label === 'Awaiting Collection') {
            setStep((st) => ({
                ...st,
                isAwaitingModal: true
            }));
        }
    };

    return (
        <div className={classes.root}>
            <Stepper
                className="stepTest"
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector active={activeStep} />}
                style={{
                    padding: '5px 0px 5px 0px',
                    margin: '0px -5px 0px -5px'
                }}
            >
                {step.steps.map((label, i) => (
                    <Tooltip disableFocusListener title={`${label.title} ${label.value ? label.value : ''}`} key={i}>
                        <Step
                            onClick={
                                props.currentStatus == i
                                    ? () => {
                                          popUpHandler(label.title);
                                      }
                                    : () => false
                            }
                            style={{ cursor: props.currentStatus == i ? 'pointer' : 'default' }}
                        >
                            <StepLabel className="Stepper-label" StepIconComponent={ColorlibStepIconWrapper(step.steps.length)}>
                                <div className={`${activeStep === i ? '' : 'stepper-label-to-hide'}`}>
                                    <div>{label.title}</div>
                                    <Typography variant="caption" display="block">
                                        {label.value}
                                    </Typography>
                                </div>
                            </StepLabel>
                        </Step>
                    </Tooltip>
                ))}
            </Stepper>
            {step.isBookedModal && (
                <DialogComp title="Status Update" onClose={() => closeModal(false)} maxWidth="md" fullWidth overflow="auto">
                    <BookedStatusModal onClose={closeModal} statusList={props.vehicleStatusList} headerRecordID={props.headerRecordID} />
                </DialogComp>
            )}
            {step.isArrivedModal && (
                <DialogComp title="Status Update" onClose={() => closeModal(false)} maxWidth="md" fullWidth>
                    <ArrivedStatusModal
                        onClose={closeModal}
                        statusList={props.vehicleStatusList}
                        headerRecordID={props.headerRecordID}
                        partStatusList={props.partStatusList}
                    />
                </DialogComp>
            )}
            {step.isAwaitingModal && (
                <DialogComp title="Status Update" onClose={() => closeModal(false)} maxWidth="md" fullWidth overflow="auto">
                    <AwaitingStatusModal onClose={closeModal} statusList={props.vehicleStatusList} headerRecordID={props.headerRecordID} />
                </DialogComp>
            )}
            {step.isOnSiteModal && (
                <DialogComp title="Status Update" onClose={() => closeModal(false)} maxWidth="md" fullWidth>
                    <OnSiteStatusModal
                        onClose={closeModal}
                        statusList={props.vehicleStatusList}
                        headerRecordID={props.headerRecordID}
                        partStatusList={props.partStatusList}
                    />
                </DialogComp>
            )}
            {step.isWorkCompletedModal && (
                <DialogComp title="Status Update" onClose={() => closeModal(false)} maxWidth="md" fullWidth>
                    <WorkCompletedStatusModal
                        onClose={closeModal}
                        statusList={props.vehicleStatusList}
                        headerRecordID={props.headerRecordID}
                        partStatusList={props.partStatusList}
                    />
                </DialogComp>
            )}
        </div>
    );
}
