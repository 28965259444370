import { DeveloperBoard } from '@material-ui/icons';
import { post, put } from './http-calls';
import { AppStorage } from './storage-service';

export const login = async (userName, password) => {
    let res = await post('/login', {
        loginName: userName,
        password: password
    });
    if (res.success) {
        AppStorage.performLogin(res.data);
    }
    return res;
};

export const postSignature = async (data) => {
    let res = await post(`/SaveSignature`, data, { useAuthToken: true });
    return res;
};

export const postSignatureStatus = async (ID) => {
    let res = await put(`/SetSignatureStatus/${ID}`, { id: ID }, { useAuthToken: true });
    return res;
};
